import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Recipient } from "@utility-types";
import { Addable } from "generated/graphql";

import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { ToggleButton } from "components/design-system/Button";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";

import PaneHeader from "components/design-system/ui/PaneHeader";
import AutoSuggestSubjectInput from "./AutoSuggestSubjectInput";
import { State } from "./DraftReducer";
import { DraftForm } from "./types";

type Props = {
  autoSuggestSubject: boolean;
  compose: State;
  isModal: boolean;
  onAddableChange: (addable: Addable) => void;
  onRecipientsChange: (recipients: Recipient[]) => void;
  onSubjectChange: (subject: string) => void;
  onClose: (() => void) | undefined;
  readOnly: boolean;
};

const ThreadComposeHeader = ({
  autoSuggestSubject,
  compose,
  isModal,
  onAddableChange,
  onRecipientsChange,
  onSubjectChange,
  onClose,
  readOnly,
}: Props) => {
  const { getFieldState, watch } = useFormContext<DraftForm>();

  // Watch form values and call callbacks
  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== "change") return;
      switch (name) {
        case "recipients":
          onRecipientsChange(values.recipients as Recipient[]);
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [getFieldState, onRecipientsChange, watch]);

  // Render

  const addableNone = compose.draftForm?.recipientsAddable === Addable.None;
  const isGlueAI = isGlueAIRecipient(compose.draftForm.recipients);

  return (
    <PaneHeader
      onClose={onClose}
      padding={isModal ? "thread-compose-modal" : "thread-compose"}
    >
      <div className="flex flex-row items-start grow min-w-0">
        {!isModal && <BackStackButton />}
        <div className="flex grow min-w-0">
          <div className="flex flex-col grow min-w-0 md:gap-4">
            <AutoSuggestSubjectInput
              autoSuggestSubject={autoSuggestSubject}
              className="mr-28"
              compose={compose}
              onSubjectChange={onSubjectChange}
              isGlueAI={isGlueAI}
              readOnly={readOnly}
            />
            <div className="flex items-end mb-8 md:mb-4">
              <RecipientsSelect<DraftForm>
                autoFocus={false}
                borderWidth={0}
                className="z-2 w-full border-none"
                disabled={readOnly}
                filterMe={true}
                maxLines={3}
                name="recipients"
                placeholder="To groups, users, or emails..."
                wrapperClassName="!my-0 grow min-w-0"
                includeGlueAI
              />
              <ToggleButton
                buttonStyle="none"
                className="!p-8 ml-8 mb-2 mr-2"
                disabledState={{
                  icon: "Unlock",
                  iconClassName: "text-icon-subtle",
                  title: "Lock recipient list",
                }}
                enabledState={{
                  icon: "Lock",
                  iconClassName: "text-accent-badge",
                  title: "Unlock recipient list",
                }}
                iconSize={18}
                onClick={() =>
                  onAddableChange(addableNone ? Addable.Anyone : Addable.None)
                }
                toggled={addableNone}
              />
            </div>
          </div>
        </div>
      </div>
    </PaneHeader>
  );
};

export default ThreadComposeHeader;
