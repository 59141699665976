import { ThreadEdgeSimple } from "@utility-types";
import { Footer, Header, Main } from "components/ModalKit";
import { ModalProps } from "components/ModalKit/Modal";
import { StandardModal } from "components/Modals";
import { Button } from "components/design-system/Button";
import { Form, SubmitButton } from "components/design-system/Forms";
import DatePicker from "components/design-system/Forms/DatePicker";
import TimePicker from "components/design-system/Forms/TimePicker";
import { format, isToday } from "date-fns";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import useModalStore from "store/useModalStore";

type FormValues = {
  date: Date;
  time: string;
};

const FormContent = () => {
  const { watch, resetField } = useFormContext<FormValues>();
  const { date } = watch();

  useEffect(() => {
    if (!isToday(date)) return;
    resetField("time");
  }, [date, resetField]);

  return (
    <div className="flex grow gap-8 py-20">
      <div className="w-3/5">
        <DatePicker
          name="date"
          placeholder="Select a date"
          calendarProps={{ disabled: { before: new Date() } }}
        />
      </div>
      <div className="w-2/5">
        <TimePicker name="time" fromNow={isToday(date)} />
      </div>
    </div>
  );
};

const ThreadReminderCustomModal = ({
  threadEdge,
  ...props
}: { threadEdge: ThreadEdgeSimple } & ModalProps) => {
  const { closeModal } = useModalStore(({ closeModal }) => ({ closeModal }));
  const { markThreadEdges } = useInboxThreadActions();
  const addReminder = ({ date, time }: FormValues) => {
    const reminderDate = new Date(
      `${date.toDateString()} ${time}`
    ).toISOString();
    markThreadEdges({
      selection: { threadEdges: [threadEdge] },
      remindAt: reminderDate,
    });
    closeModal(props.modalId);
  };
  return (
    <StandardModal {...props} contentClassName="md:max-w-[564px]">
      <Header variant="bordered">
        Remind me
        <div className="flex grow" />
        <span className="bg-background-secondary rounded-half text-text-subtle text-footnote-bold px-12 py-3 mr-12">
          {format(new Date(), "z")}
        </span>
      </Header>
      <Form<FormValues>
        className="flex flex-col"
        useFormProps={{ defaultValues: { date: new Date() } }}
        onSubmit={addReminder}
      >
        <Main className="py-24 pl-32 pr-20">
          <FormContent />
        </Main>
        <Footer>
          <Button
            buttonStyle="subtle"
            onClick={() => closeModal(props.modalId)}
            type="button"
          >
            Cancel
          </Button>
          <SubmitButton>Set reminder</SubmitButton>
        </Footer>
      </Form>
    </StandardModal>
  );
};

export default ThreadReminderCustomModal;
