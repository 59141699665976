import { ObservableQuery } from "@apollo/client";
import { Workspace, nodeAs } from "@utility-types";
import InviteToGlueModal from "components/InviteToGlue/InviteToGlueModal";
import { MembersListHeader } from "components/MembersList/MembersListHeader";
import { MembersListItem } from "components/MembersList/MembersListItem";
import { PendingMembersListItem } from "components/MembersList/PendingMembersListItem";
import { ModalProps } from "components/ModalKit/Modal";
import { ConfirmationModal } from "components/Modals";
import ConfirmationRequiredModal from "components/Modals/ConfirmationRequiredModal";
import { Button } from "components/design-system/Button";
import {
  FetchWorkspaceOrPreviewEdgeQuery,
  MemberRole,
  WorkspaceFieldsFragment,
  useAddWorkspaceMembersMutation,
  useApproveJoinApprovalMutation,
  useCancelRequestJoinThreadMutation,
  useLeaveWorkspaceMutation,
  useRemindWorkspaceMembersMutation,
  useRemoveWorkspaceMembersMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useSnackbar } from "providers/SnackbarProvider";
import { ReactNode, useCallback, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";
import { WorkspacePendingInvitationsBanner } from "./members/WorkspacePendingInvitationsBanner";

const remindAllCountThreshold = 2;

type WorkspaceMemberEdge = WorkspaceFieldsFragment["members"]["edges"][number];
type PendingApprovalEdge =
  WorkspaceFieldsFragment["pendingApprovals"]["edges"][number];
type SortedMemberItem = { isPending: false; data: WorkspaceMemberEdge };
type PendingApprovalItem = { isPending: true; data: PendingApprovalEdge };
type Item = SortedMemberItem | PendingApprovalItem;

type Props = {
  workspace: Workspace;
  fetchMore: ObservableQuery<FetchWorkspaceOrPreviewEdgeQuery>["fetchMore"];
  hasError: boolean;
  isAdmin: boolean;
  modalId: ModalProps["modalId"];
  scrollContainerRef: React.RefObject<HTMLElement>;
};

const WorkspaceMembers = ({
  workspace,
  isAdmin,
  hasError,
  fetchMore,
  modalId,
  scrollContainerRef,
}: Props) => {
  const [hasResentInvitations, setHasResentInvitations] = useState(false);
  const [memberIdsResentInvitation, setMemberIdsResentInvitation] = useState<
    string[]
  >([]);
  const { authData, fetchAuthData } = useAuthData();
  const [editingMemberId, setEditingMemberId] = useState<string | null>(null);
  const { members, pendingApprovals } = workspace;
  const [approveJoinApproval] = useApproveJoinApprovalMutation({
    refetchQueries: ["FetchWorkspaceOrPreviewEdge"],
  });

  const [cancelRequestJoinThread] = useCancelRequestJoinThreadMutation({
    refetchQueries: ["FetchWorkspaceOrPreviewEdge"],
  });
  const [leaveWorkspace] = useLeaveWorkspaceMutation();
  const [removeWorkspaceMembers] = useRemoveWorkspaceMembersMutation();
  const [updateWorkspaceMembers] = useAddWorkspaceMembersMutation();
  const [remindWorkspaceMembers] = useRemindWorkspaceMembersMutation();
  const { openModal, closeModal } = useModalStore(
    ({ openModal, closeModal }) => ({
      openModal,
      closeModal,
    })
  );

  const loadMore = useCallback(() => {
    if (hasError) {
      return;
    }

    // we display pending approvals first, so we fetch more of those first
    if (pendingApprovals?.pageInfo?.hasNextPage) {
      return fetchMore({
        variables: {
          pendingApprovalsAfter: pendingApprovals.pageInfo.endCursor,
        },
      }).catch(err => {
        console.warn("Error: [WorkspaceJoinApprovals] - ", err.message);
      });
    }

    if (members.pageInfo.hasNextPage) {
      return fetchMore({
        variables: {
          membersAfter: members.pageInfo.endCursor,
        },
      }).catch(err => {
        console.warn("Error: [WorkspaceMembers] - ", err.message);
      });
    }
  }, [
    hasError,
    pendingApprovals.pageInfo?.hasNextPage,
    pendingApprovals.pageInfo.endCursor,
    members.pageInfo.hasNextPage,
    members.pageInfo.endCursor,
    fetchMore,
  ]);

  const totalPendingInvitations =
    members.edges.filter(m => m.pending).length + pendingApprovals.totalCount;

  const { openSnackbar } = useSnackbar();

  const handleAddMember = () => {
    openModal(
      <InviteToGlueModal defaultWorkspaceID={workspace.id} inviteToWorkspace />
    );
  };

  const handleUpdateRole = ({ id, role }: { id: string; role: MemberRole }) =>
    updateWorkspaceMembers({
      variables: {
        id: workspace.id,
        members: [{ member: id, role }],
      },
    });

  const handleDelete = (memberId: string) => {
    const member = members.edges.find(m => m.node.id === memberId);

    if (!member) {
      return;
    }

    const isManagedByWorkspace = workspace.domains.some(domain =>
      member.node.addressDomains.includes(domain)
    );

    if (isManagedByWorkspace) {
      openModal(
        <ConfirmationModal
          confirmLabel="Revoke access"
          header={`Remove ${member.node.name} from Glue?`}
          message={`Removing ${member.node.name} will revoke their access to Glue, which is managed by ${workspace.name}. They may also lose access to other associated workspaces.`}
          isDestructive
          onConfirm={() =>
            removeWorkspaceMembers({
              variables: { id: workspace.id, memberIDs: [member.node.id] },
            })
          }
        />
      );
    } else {
      openModal(
        <ConfirmationModal
          confirmLabel="Remove access"
          header={`Remove ${member.node.name} from ${workspace.name}?`}
          message={`${member.node.name} will no longer be able to access ${workspace.name}.`}
          isDestructive
          onConfirm={() =>
            removeWorkspaceMembers({
              variables: { id: workspace.id, memberIDs: [member.node.id] },
            })
          }
        />
      );
    }
  };

  const handleLeave = () => {
    openModal(
      <ConfirmationRequiredModal
        title={`Leave ${workspace.name}?`}
        subtitle={`Are you sure you want to leave the workspace ${workspace.name}? This action is irreversible, and you will lose access to all associated groups and threads.`}
        confirmButtonText="Leave workspace"
        confirmationWord="LEAVE"
        onConfirm={() =>
          leaveWorkspace({
            variables: { id: workspace.id },
          })
            .then(() => {
              fetchAuthData({ refresh: true });
              closeModal(modalId);
              openSnackbar("success", `Left workspace ${workspace.name}`);
            })
            .catch(err => {
              console.warn("Error: [WorkspaceMembers] - ", err.message);

              if (err.message.includes("cannot remove last admin")) {
                openSnackbar(
                  "error",
                  "Cannot remove last admin from workspace"
                );
              }
            })
        }
      />
    );
  };

  const handleApprovePendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await approveJoinApproval({ variables: { joinApprovalID: approvalId } });
  };

  const handleCancelPendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await cancelRequestJoinThread({ variables: { id: approvalId } });
  };

  const handleResendPendingApproval = async (approvalId: string) => {
    const approval = pendingApprovals.edges.find(a => a.node.id === approvalId);

    if (!approval) {
      return;
    }

    await remindWorkspaceMembers({
      variables: {
        id: workspace.id,
        input: {
          memberIDs: [approval.node.joining.id],
        },
      },
      onCompleted: () =>
        setMemberIdsResentInvitation(s => [...s, approval.node.joining.id]),
    });
  };

  const items: Item[] = [
    ...pendingApprovals.edges.map<PendingApprovalItem>(edge => ({
      isPending: true,
      data: edge,
    })),
    ...members.edges.map<SortedMemberItem>(edge => ({
      isPending: false,
      data: edge,
    })),
  ];

  return (
    <Virtuoso
      customScrollParent={scrollContainerRef.current ?? undefined}
      className="native:pb-safe-area h-full w-full"
      data={items}
      endReached={loadMore}
      increaseViewportBy={400}
      components={{
        Header: () => (
          <>
            <MembersListHeader
              className="my-16"
              addNewMemberButton={
                isAdmin && (
                  <Button
                    icon="Plus"
                    buttonStyle="simplePrimary"
                    buttonType="text"
                    onClick={handleAddMember}
                  >
                    Add new member
                  </Button>
                )
              }
            >
              {members.totalCount} members
              {totalPendingInvitations > 0 &&
                `, ${totalPendingInvitations} pending`}
            </MembersListHeader>

            {isAdmin &&
              totalPendingInvitations >= remindAllCountThreshold &&
              !hasResentInvitations && (
                <WorkspacePendingInvitationsBanner
                  workspace={workspace}
                  count={totalPendingInvitations}
                  onCompleteResend={() => setHasResentInvitations(true)}
                />
              )}
          </>
        ),
        Footer: () => (
          <div className="native:pb-safe-area pt-16">
            {members.pageInfo.hasNextPage && (
              <div className="text-text-subtle pb-16">Loading…</div>
            )}
          </div>
        ),
      }}
      itemContent={(index, { isPending, data }) => {
        const isFirstItem = index === 0;
        const isLastItem = index === items.length - 1;
        let item: ReactNode;
        if (isPending === true) {
          const {
            node: { id, requester, joining, admin, requestedAt },
          } = data;
          const joiningUser = nodeAs(joining, ["User"]);
          if (!joiningUser) {
            return null;
          }
          item = (
            <PendingMembersListItem
              key={id}
              approvalID={id}
              isAdmin={isAdmin}
              isByMe={requester.id === authData?.me.id}
              isForMe={joining.id === authData?.me.id}
              isInvite={requester.id === admin?.id}
              joining={joiningUser}
              requestedAt={new Date(requestedAt)}
              onApprove={handleApprovePendingApproval}
              onCancel={handleCancelPendingApproval}
              onResend={
                hasResentInvitations ||
                memberIdsResentInvitation.includes(joining.id)
                  ? undefined
                  : handleResendPendingApproval
              }
            />
          );
        } else {
          item = (
            <MembersListItem
              key={data.node.id}
              member={{
                ...data.node,
                role: data.memberRole,
                pending: data.pending,
              }}
              onUpdateRole={handleUpdateRole}
              onDelete={handleDelete}
              onLeave={handleLeave}
              onResend={
                !hasResentInvitations &&
                !memberIdsResentInvitation.includes(data.node.id)
                  ? handleResendPendingApproval
                  : undefined
              }
              isEditing={editingMemberId === data.node.id}
              setIsEditing={(state: boolean) =>
                setEditingMemberId(state ? data.node.id : null)
              }
              isAdmin={isAdmin}
              isMe={authData?.me.id === data.node.id}
            />
          );
        }

        return (
          <div
            className={tw({
              "mt-10": !isFirstItem,
              "mb-10": !isLastItem,
            })}
          >
            {item}
          </div>
        );
      }}
    />
  );
};

export default WorkspaceMembers;
