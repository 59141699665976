import { ReactComponent as GlueLogo } from "assets/glue/glue-drop-mono-dark.svg";

/**
 * @summary Renders the empty state for the main content pane with a Glue drop watermark.
 */

const EmptyView = () => {
  return (
    <div className="flex items-center justify-center h-full w-full bg-background-body">
      <GlueLogo className="bg-contain bg-no-repeat w-[61px] h-[80px] text-background-app-secondary" />
    </div>
  );
};

export default EmptyView;
