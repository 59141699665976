import { MouseEvent, useCallback, useMemo, useRef } from "react";

import {
  Message,
  ThreadEdge,
  ThreadPreview,
  ThreadPreviewEdge,
  ThreadSimple,
  nodeIs,
} from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";

import useThreadAction from "components/MessageEditor/components/controls/ThreadActions/hooks/useThreadAction";
import PaneHeader from "components/design-system/ui/PaneHeader";
import ThreadInfoModal from "components/thread/ThreadView/components/ThreadInfoModal";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import useMemberEdge from "hooks/useMemberEdge";
import useModalStore from "store/useModalStore";

import ThreadActionMenu from "../ThreadActionMenu";

import { shouldSummarizeThread } from "components/Summaries/Util";
import FollowButton from "./FollowButton";
import PinnedButton from "./PinnedButton";
import ThreadInfo from "./ThreadInfo";
import ThreadMembersCount from "./ThreadMembersCount";
import ThreadReminderBanner from "./ThreadReminderBanner";

const desktopWidth = 480;

type Props = {
  headerType?: "simple" | "full";
  onClose?: () => void;
  replyTo:
    | {
        message: Message;
        thread: ThreadSimple | ThreadPreview;
      }
    | undefined;
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
};

const ThreadHeader = ({
  headerType = "full",
  onClose,
  replyTo,
  threadEdge,
}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const threadID = threadEdge?.node.id;
  const {
    available: canSummarize,
    loading: summarizing,
    performThreadAction: performSummarizeThreadAction,
  } = useThreadAction({ threadID, name: "summarize_thread" });

  const desktopBreakpoint = useElementBreakpoint(
    headerRef.current,
    desktopWidth
  );

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);

  const recipients = useMemo(
    () => threadEdge?.node?.recipients.edges.map(e => e.node),
    [threadEdge?.node?.recipients.edges]
  );

  const onClickModal = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (!threadEdge) return;
      openModal(<ThreadInfoModal threadID={threadEdge.node.id} focusSubject />);
    },
    [openModal, threadEdge]
  );

  // summarization
  const summarize = async () => {
    if (!canSummarize) {
      return;
    }

    await performSummarizeThreadAction?.();
  };

  const showSummarizeButton =
    memberEdge && !!canSummarize && shouldSummarizeThread(memberEdge.node);

  return (
    <PaneHeader
      ref={headerRef}
      onClose={onClose}
      trailingContent={
        nodeIs(threadEdge, ["ThreadEdge"]) && (
          <ThreadReminderBanner threadEdge={threadEdge} />
        )
      }
    >
      <div className="flex flex-col grow min-w-0">
        <div className="flex items-start min-h-[52px]">
          <div className="inline-flex grow min-w-0">
            <BackStackButton />
            <ThreadInfo
              headerType={headerType}
              onClick={memberEdge ? onClickModal : undefined}
              recipients={recipients}
              replyTo={replyTo}
              subject={threadEdge?.node.subject}
              isPreviewOnly={!memberEdge}
            />
          </div>
          <div className="flex grow-0 shrink-0 justify-end py-2 gap-8">
            {headerType === "full" && desktopBreakpoint && !previewEdge ? (
              <ThreadMembersCount edge={memberEdge} onClick={onClickModal} />
            ) : null}

            {memberEdge && <PinnedButton threadID={memberEdge.node.id} />}

            {desktopBreakpoint && memberEdge && (
              <FollowButton threadEdge={memberEdge} />
            )}

            {memberEdge && (
              <ThreadActionMenu
                additionalActions={
                  showSummarizeButton
                    ? [
                        {
                          icon: "SparkleFilled" as const,
                          text: "Summarize thread",
                          onClick: summarize,
                        },
                      ]
                    : []
                }
                threadEdge={threadEdge}
                threadContainerBreakpointMD={!!desktopBreakpoint}
                loading={!desktopBreakpoint && summarizing}
              />
            )}
          </div>
        </div>
      </div>
    </PaneHeader>
  );
};

export default ThreadHeader;
