import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Clipboard } from "@capacitor/clipboard";
import {
  GroupEdge,
  GroupOrWorkspaceAndPreview,
  GroupPreviewEdge,
} from "@utility-types";
import { Button } from "components/design-system/Button";
import { Dropdown } from "components/design-system/FloatingUi";
import {
  ActionSheetItemGroups,
  DropdownActions,
} from "components/design-system/FloatingUi/DropdownActions";
import GroupProfileModal, {
  GroupProfileTab,
} from "components/group/GroupModal/GroupProfileModal";
import useShowAppsOption from "components/group/GroupModal/hooks/useShowAppsOption";
import {
  MailboxCountsDocument,
  MemberRole,
  WorkspacesAndGroupsListDocument,
  useLeaveGroupMutation,
} from "generated/graphql";
import { useCacheEvict } from "hooks/state/useCacheEvict";
import { useSnackbar } from "providers/SnackbarProvider";
import useModalStore from "store/useModalStore";

import { ConfirmationModal } from "components/Modals";
import { routeURL, superTabsDefaults } from "components/routing/utils";

type Props = {
  groupEdge?: GroupEdge | GroupPreviewEdge;
  additionalActions?: ActionSheetItemGroups["items"];
};

const GroupActionMenu = ({
  groupEdge,
  additionalActions,
}: Props): JSX.Element | null => {
  const { evictNode } = useCacheEvict();
  const history = useHistory();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { openSnackbar } = useSnackbar();

  const isAdmin =
    !!groupEdge &&
    "memberRole" in groupEdge &&
    groupEdge.memberRole === MemberRole.Admin;

  const showApps = useShowAppsOption({
    groupEdge,
    isAdmin,
  });

  const isFollowed =
    groupEdge?.__typename === "GroupEdge" &&
    groupEdge.threadSubscription === "inbox";

  const groupOrWorkspace: GroupOrWorkspaceAndPreview | undefined =
    groupEdge?.node;
  const inGroup = !groupOrWorkspace?.__typename.endsWith("Preview");

  const [leaveGroup] = useLeaveGroupMutation({
    errorPolicy: "all",
  });

  const handleLeaveGroup = useCallback(() => {
    if (!groupOrWorkspace) {
      return;
    }

    openModal(
      <ConfirmationModal
        confirmLabel="Leave Group"
        header={`Leave "${groupOrWorkspace.name}"?`}
        message="You'll lose access to threads sent to the group."
        onConfirm={() =>
          leaveGroup({
            refetchQueries: [
              MailboxCountsDocument,
              WorkspacesAndGroupsListDocument,
            ],
            variables: { id: groupOrWorkspace.id },
          }).then(() => {
            evictNode(groupOrWorkspace);
            history.replace(superTabsDefaults.groups);
          })
        }
      />
    );
  }, [evictNode, groupOrWorkspace, history, leaveGroup, openModal]);

  const handleCopyShareURL = () => {
    if (!groupOrWorkspace?.id) return;
    Clipboard.write({
      url: routeURL({ recipientID: groupOrWorkspace.id }),
    }).then(() =>
      openSnackbar("info", "Share link copied to clipboard.", 5000)
    );
  };

  const handleGroupSubscription = () => {
    if (groupEdge?.__typename === "GroupEdge") {
      openModal(
        <GroupProfileModal
          defaultTab={GroupProfileTab.Notifications}
          groupID={groupEdge.node.id}
          isAdmin={isAdmin}
        />
      );
    }
  };

  const openGroupProfileModal = useCallback(
    (tab?: GroupProfileTab) => {
      groupEdge &&
        openModal(
          <GroupProfileModal
            defaultTab={tab}
            groupID={groupEdge.node.id}
            isAdmin={isAdmin}
          />
        );
    },
    [groupEdge, openModal, isAdmin]
  );

  const membersItem = {
    icon: "Users" as const,
    text: "Members",
    onClick: () => openGroupProfileModal(GroupProfileTab.Members),
  };

  const actions: ActionSheetItemGroups[] = [
    {
      items: [
        ...(additionalActions ?? []),
        ...(groupEdge?.__typename !== "GroupPreviewEdge" ? [membersItem] : []),
      ],
      name: "members",
    },
  ];

  showApps &&
    actions[0]?.items.push({
      icon: "LightningBolt",
      onClick: () => openGroupProfileModal(GroupProfileTab.Apps),
      text: "Apps",
    });

  if (inGroup) {
    actions[0]?.items.push({
      icon: isFollowed ? "BellFilled" : "Bell",
      iconSize: isFollowed ? 20 : 22,
      onClick: handleGroupSubscription,
      text: "Notifications",
    });
  }

  actions[0]?.items.push({
    icon: "ExternalLink",
    onClick: handleCopyShareURL,
    text: "Copy share link",
  });

  if (isAdmin) {
    actions.push({
      items: [
        {
          icon: "Settings",
          onClick: () => openGroupProfileModal(GroupProfileTab.Settings),
          text: "Group settings",
        },
      ],
      name: "admin",
    });
  } else if (inGroup) {
    actions.push({
      items: [
        {
          icon: "Leave",
          onClick: handleLeaveGroup,
          text: "Leave group",
        },
      ],
      name: "admin",
    });
  }

  return (
    <Dropdown
      content={<DropdownActions actions={groupEdge ? actions : []} />}
      disableFlip
    >
      <Button
        buttonStyle="subtle"
        className="h-28 w-28 justify-center rounded-md !border-border-container hover:bg-background-secondary"
        icon="MenuVertical"
        iconSize={20}
      />
    </Dropdown>
  );
};

export default GroupActionMenu;
