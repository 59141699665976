import { ModalProps } from "components/ModalKit/Modal";
import { Button } from "components/design-system/Button";
import localSettingsStore from "store/useLocalSettingsStore";
import modalStore from "store/useModalStore";

import illustrationNotification from "assets/illustration-notification.svg";
import { StandardModal } from "components/Modals";

import { requestPermission } from "./permissions";

const NotificationPreAlertModal = (props: ModalProps) => {
  const { closeModal } = modalStore(({ closeModal }) => ({ closeModal }));

  return (
    <StandardModal
      closeable={false}
      contentHandlesSafeArea={false}
      heightAuto
      mobileGap
      {...props}
    >
      <div className="flex flex-col items-center py-32 px-32 md:px-64">
        <img
          alt="Notification bell icon"
          src={illustrationNotification}
          width={56}
          height={56}
        />
        <span className="mt-16 text-center text-title-3">
          Turn on notifications
        </span>
        <span className="w-full md:w-[420px] h-[66px] md:h-44 text-center text-headline">
          Stay in the loop with Glue! Enable push notifications to keep up with
          real time threads and messages.
        </span>
        <Button
          className="w-full md:w-[420px] mt-32 justify-center"
          onClick={() => {
            requestPermission();
            closeModal(`${props.modalId}`);
          }}
        >
          Continue
        </Button>
        <Button
          testId="notification-pre-alert-modal-maybe-later-button"
          buttonStyle="subtle"
          className="w-full md:w-[420px] mt-16 justify-center"
          onClick={() => {
            localSettingsStore.setState({ notificationAskedAt: Date.now() });
            closeModal(`${props.modalId}`);
          }}
        >
          Maybe later
        </Button>
      </div>
    </StandardModal>
  );
};

export default NotificationPreAlertModal;
