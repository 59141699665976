import {
  useFetchThreadMetadataQuery,
  useUserSettingsQuery,
} from "generated/graphql";

export type UseLlmModelResult = {
  defaultModel?: string;
  defaultModelLoading?: boolean;
};

const useLlmModel = (threadID = ""): UseLlmModelResult => {
  const { data: userSettings } = useUserSettingsQuery({
    fetchPolicy: "cache-and-network",
  });

  const { data: threadMetadata, loading: defaultModelLoading } =
    useFetchThreadMetadataQuery({
      fetchPolicy: "cache-first",
      skip: !threadID,
      variables: { threadID },
    });

  return {
    defaultModel:
      threadMetadata?.threadMetadata?.aiSettings?.chatModel ||
      userSettings?.settings.llmSettings.chatModel,
    defaultModelLoading,
  };
};

export default useLlmModel;
