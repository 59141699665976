import { animated } from "@react-spring/web";
import analytics from "analytics";
import {
  ThreadActionOrigin,
  ThreadArchivedUnarchived,
  ThreadMarkedReadUnread,
  ThreadStarredUnstarred,
  ThreadSubscribedUnsubscribed,
} from "analytics/events/thread";
import Button from "components/design-system/Button/Button";
import Icon from "components/design-system/icons/Icon";
import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import ThreadReminderModalMobile from "components/threads/ThreadReminders/ThreadReminderModalMobile";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import useModalStore from "store/useModalStore";
import tw from "utils/tw";
import { useSwipe } from "./hooks/useSwipe";
import { className } from "./styles";
import { SwipeStyleProps, ThreadSwipeProps } from "./types";

const DEFAULT_RIGHT_LIMIT = 120;

const Thread = ({
  actionBarPadding,
  borderRadius,
  canArchive,
  canFollow = true,
  canRemind = false,
  children,
  dismissDelay = 200,
  dismissOnRightSwipe = false,
  height,
  leftActionPadding,
  setSwipedOpenItemId,
  swipedOpenItemId,
  itemData: threadEdge,
}: ThreadSwipeProps & SwipeStyleProps): JSX.Element | null => {
  const inboxThreadActions = useInboxThreadActions();
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const LEFT_LIMIT = (() => {
    let result = -104; // 2 icons * 44px/icon + 16px horizontal padding
    if (canFollow) {
      result -= 44; // 1 icon * 44px/icon
    }
    if (canRemind) {
      result -= 44; // 1 icon * 44px/icon
    }
    return result;
  })();

  const isArchived = threadEdge?.isArchived;
  const isRead = threadEdge?.isRead;
  const isStarred = threadEdge?.isStarred;
  const isNotSubscribed = threadEdge && !isSubscribed(threadEdge);
  const threadId = threadEdge.id.split("-")[0] ?? "";

  const { toggleThreadRead, toggleThreadStarred, toggleThreadSubscribed } =
    inboxThreadActions;

  const { bind, pos, resetSwipe, swipeState, x } = useSwipe({
    rightSwipe: canArchive,
    rightSwipeAndDismiss: reset => {
      if (!canArchive) {
        reset();
        return false;
      }

      setTimeout(
        () => {
          inboxThreadActions.toggleThreadArchived(threadEdge);
          analytics.track(ThreadArchivedUnarchived, {
            archived: !isArchived,
            threadId,
            uiOrigin: ThreadActionOrigin.SwipedListItem,
          });
        },
        dismissDelay // leave time for dismiss animation
      );

      return dismissOnRightSwipe;
    },
    leftLimit: LEFT_LIMIT,
    rightLimit: DEFAULT_RIGHT_LIMIT,
    children,
    setSwipedOpenItemId,
    swipedOpenItemId,
    threadID: threadEdge?.id,
  });

  return (
    <div className={tw(className.listItem, height, borderRadius)}>
      <div className={tw(className.actionBar, actionBarPadding, borderRadius)}>
        <div
          className={tw(
            className.actionLeft,
            "bg-background-list-done",
            {
              hidden: swipeState !== "right" || !canArchive,
            },
            {
              "bg-background-list-done-hover !max-w-full":
                pos > DEFAULT_RIGHT_LIMIT,
            },
            leftActionPadding,
            borderRadius
          )}
        >
          <div className="flex items-center">
            <Icon icon={isArchived ? "Inbox" : "Check"} className="mr-4" />
            {isArchived ? "Move to Inbox" : "Archive"}
          </div>
        </div>
        <div
          className={tw(
            className.actionRight,
            {
              hidden: swipeState !== "left",
            },
            borderRadius
          )}
        >
          <Button
            className={tw(className.actionButton, {
              hidden: !canFollow,
            })}
            buttonStyle="none"
            data-testid="swipe-right-mute-action"
            onClick={() => {
              if (threadEdge) {
                toggleThreadSubscribed(threadEdge);
                analytics.track(ThreadSubscribedUnsubscribed, {
                  subscribed: isNotSubscribed === true,
                  threadId,
                  uiOrigin: ThreadActionOrigin.SwipedListItem,
                });
              }
              setSwipedOpenItemId();

              resetSwipe();
            }}
          >
            <Icon
              icon={threadEdge && isNotSubscribed ? "Bell" : "BellSmallFilled"}
              className={className.actionIcon}
            />
          </Button>
          <Button
            className={className.actionButton}
            buttonStyle="none"
            data-testid="swipe-right-mark-read-action"
            onClick={() => {
              if (threadEdge) {
                threadEdge && toggleThreadRead(threadEdge);
                analytics.track(ThreadMarkedReadUnread, {
                  read: !isRead,
                  threadId,
                  uiOrigin: ThreadActionOrigin.SwipedListItem,
                });
              }
              setSwipedOpenItemId();

              resetSwipe();
            }}
          >
            <Icon
              icon={isRead ? "Unread" : "Mail"}
              className={className.actionIcon}
            />
          </Button>
          {canRemind && (
            <Button
              className={className.actionButton}
              buttonStyle="none"
              data-testid="swipe-right-remind-action"
              onClick={() => {
                if (threadEdge) {
                  openModal(
                    <ThreadReminderModalMobile threadEdge={threadEdge} />
                  );
                }
                setSwipedOpenItemId();

                resetSwipe();
              }}
            >
              <Icon icon="ClockAlarm" className={tw(className.actionIcon)} />
            </Button>
          )}
          <Button
            className={className.actionButton}
            buttonStyle="none"
            data-testid="swipe-right-star-action"
            onClick={() => {
              if (threadEdge) {
                toggleThreadStarred(threadEdge);
                analytics.track(ThreadStarredUnstarred, {
                  starred: !isStarred,
                  threadId,
                  uiOrigin: ThreadActionOrigin.SwipedListItem,
                });
              }
              setSwipedOpenItemId();

              resetSwipe();
            }}
          >
            <Icon
              icon={isStarred ? "StarFilled" : "Star"}
              className={tw(className.actionIcon, {
                "text-icon-favorite hover:text-icon-favorite-hover": isStarred,
              })}
            />
          </Button>
        </div>
      </div>
      <animated.div
        {...bind()}
        className={tw(className.swipeElement, borderRadius)}
        data-testid="thread-list-item-swipeable"
        style={{ x: pos === 0 ? 0 : x }}
      >
        {children}
      </animated.div>
    </div>
  );
};

export default Thread;
