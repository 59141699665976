import { versionInfo } from "components/App/version";

const AppVersion = () => {
  return (
    <div className="text-interactive-ghost" data-testid="app-version">
      Version:{" "}
      <span className="select-text">{versionInfo.fullVersionString}</span>
    </div>
  );
};

export default AppVersion;
