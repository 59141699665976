import { forwardRef } from "react";
import tw from "utils/tw";

type Props = {
  className?: string;
  isRead?: boolean;
  isSelected: boolean;
  subject: string | JSX.Element;
};

const AIListItem = forwardRef<HTMLDivElement, WithChildren<Props>>(
  (
    {
      children,
      className,
      isRead = true,
      isSelected,
      subject,
    }: WithChildren<Props>,
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={tw(
          "group/thread-item",
          "flex items-center justify-start",
          "pr-10 relative text-left select-none w-full h-44 md:h-32",
          "hover:bg-background-list-hover",
          {
            "!bg-background-list-selected text-text-primary": isSelected,
          },
          className
        )}
      >
        <div className="flex grow items-center justify-between min-w-0 pl-8">
          <div
            className={tw(
              "text-body truncate w-full",
              isRead ? "font-normal" : "font-semibold text-text-primary"
            )}
          >
            {subject}
          </div>

          {children}
        </div>
      </div>
    );
  }
);

export default AIListItem;
