import { ComponentProps, useState } from "react";

import { ThreadEdgeSimple } from "@utility-types";
import tw from "utils/tw";

import Icon from "components/design-system/icons/Icon";
import InboxThreadActions from "./InboxThreadActions";

type Props = {
  className?: string;
  date?: string | JSX.Element;
  threadEdge: ThreadEdgeSimple;
} & ComponentProps<typeof InboxThreadActions>;

/** The parent component should have the class `group/thread-list-item` in order to enable the hover styles */
const ThreadActionsFloatingMenu = ({
  canArchive = false,
  canFollow,
  canRemind,
  className,
  date,
  threadEdge,
}: Props) => {
  const [reminderMenuOpen, setReminderMenuOpen] = useState<boolean>(false);
  return (
    <div
      className={tw(
        "group/thread-item-actions",
        "relative h-full w-28 items-center justify-end",
        "hidden group-hover/thread-item:flex",
        { "!flex": reminderMenuOpen },
        className
      )}
    >
      <Icon
        className={tw(
          threadEdge.isStarred && !canArchive
            ? "text-icon-action-highlight fill-current"
            : "text-icon-secondary"
        )}
        icon={canArchive ? (threadEdge.isArchived ? "Inbox" : "Check") : "Star"}
        size={20}
      />
      <div
        className={tw(
          "flex items-center justify-end",
          "bg-background-body pr-4 rounded-md shadow-level1 whitespace-nowrap",
          "absolute top-1/2 right-0 translate-x-8 -translate-y-1/2 invisible -z-1", // hidden
          "group-hover/thread-item-actions:visible group-hover/thread-item-actions:z-auto", // display
          { "!visible !z-auto": reminderMenuOpen }
        )}
      >
        {date && (
          <span className="font-normal mx-12 text-sm text-text-subtle">
            {date}
          </span>
        )}

        <InboxThreadActions
          canArchive={canArchive}
          canFollow={canFollow}
          canRemind={canRemind}
          selection={{ threadEdges: [threadEdge] }}
          reminderMenuOpen={reminderMenuOpen}
          setReminderMenuOpen={setReminderMenuOpen}
        />
      </div>
    </div>
  );
};

export default ThreadActionsFloatingMenu;
