import { ComponentProps } from "react";

import { Directory, Recent } from "components/SideBar/DirectMessages";
import SidebarHeader, {
  sidebarHeaderHeight,
} from "components/SideBar/SidebarHeader/SidebarHeader";
import { Tabs } from "components/Tabs";
import { DMTabs, tabPath, useRouteParams } from "components/routing/utils";
import { useHistory } from "react-router";

type DMTabsType = keyof typeof DMTabs;
type TabsType = ComponentProps<typeof Tabs<DMTabsType>>["tabs"];

const DirectMessagesMainTabs = ({
  buttons,
  desktopHeader,
  title,
}: {
  buttons?: React.ReactNode;
  desktopHeader?: boolean;
  title?: string;
}) => {
  const history = useHistory();
  const { t: tab } = useRouteParams();

  const currentTab = (() => {
    switch (tab) {
      case "Recent":
        return DMTabs.Recent;
      case "Directory":
        return DMTabs.Directory;
      default:
        return DMTabs.Recent;
    }
  })();

  const tabs: TabsType = [
    { tab: DMTabs.Recent, tabTitle: DMTabs.Recent },
    { tab: DMTabs.Directory, tabTitle: DMTabs.Directory },
  ];

  return (
    <div className="flex flex-col h-full w-full">
      {desktopHeader ? (
        <SidebarHeader
          buttons={buttons}
          className={`h-[${sidebarHeaderHeight}px]`}
          title={title}
        >
          <Tabs<DMTabsType>
            buttonClassName="border-b-2 capitalize h-[23px] mr-24 pb-7 px-0 text-sm"
            buttonSelectedClassName="border-accent-primary text-text-primary"
            buttonUnselectedClassName="border-transparent text-text-secondary cursor-pointer"
            className="pl-20"
            onClickTab={tab => history.push(tabPath(tab))}
            selectedTab={currentTab}
            tabs={tabs}
          />
        </SidebarHeader>
      ) : (
        <Tabs<DMTabsType>
          buttonClassName="border-b-2 box-content capitalize h-[28px] justify-center pb-6 text-body-bold w-full"
          buttonSelectedClassName="border-accent-primary text-text-primary"
          buttonUnselectedClassName="border-transparent text-text-subtle cursor-pointer"
          buttonWrapperClassName="flex grow items-center justify-center min-w-0"
          className="border-b-1 border-border-strong px-20"
          onClickTab={tab => history.push(tabPath(tab))}
          selectedTab={currentTab}
          tabs={tabs}
        />
      )}

      {currentTab === DMTabs.Recent && <Recent />}
      {currentTab === DMTabs.Directory && <Directory />}
    </div>
  );
};

export default DirectMessagesMainTabs;
