import { animated } from "@react-spring/web";
import Button from "components/design-system/Button/Button";
import Icon from "components/design-system/icons/Icon";
import useAIThreadActions from "components/views/ai/hooks/useAIThreadActions";
import useMemberEdge from "hooks/useMemberEdge";
import { formatDate } from "utils/formatDate";
import tw from "utils/tw";
import { useSwipe } from "./hooks/useSwipe";
import { className } from "./styles";
import { AIThreadSwipeProps, SwipeStyleProps } from "./types";

const RIGHT_LIMIT = 120;
const LEFT_LIMIT = -130;

const AIThread = ({
  actionBarPadding,
  borderRadius,
  children,
  dismissDelay = 200,
  height,
  leftActionPadding,
  setSwipedOpenItemId,
  swipedOpenItemId,
  itemData: threadEdge,
}: AIThreadSwipeProps & SwipeStyleProps): JSX.Element | null => {
  const node = useMemberEdge(threadEdge)?.memberEdge?.node;
  const { handleDeleteThread } = useAIThreadActions();

  const { bind, pos, swipeState, x } = useSwipe({
    children,
    leftLimit: LEFT_LIMIT,
    rightLimit: RIGHT_LIMIT,
    rightSwipe: true,
    rightSwipeAndDismiss: () => {
      setTimeout(() => {
        handleDeleteThread(threadEdge);
      }, dismissDelay);
      return true;
    },
    setSwipedOpenItemId,
    swipedOpenItemId,
    threadID: threadEdge?.id,
  });

  return (
    <div className={tw(className.listItem, height, borderRadius)}>
      <div className={tw(className.actionBar, actionBarPadding, borderRadius)}>
        <div
          className={tw(
            className.actionLeft,
            "bg-background-list-delete !text-text-inverse",
            {
              hidden: swipeState !== "right",
            },
            {
              "bg-background-list-delete-hover !max-w-full": pos > RIGHT_LIMIT,
            },
            leftActionPadding,
            borderRadius
          )}
        >
          <div className="flex items-center">
            <Icon icon="Trash" className="mr-4" />
            Delete
          </div>
        </div>

        <div
          className={tw(
            className.actionRight,
            {
              hidden: swipeState !== "left",
            },
            borderRadius
          )}
        >
          <span className={className.date}>
            {formatDate(
              new Date(
                node?.lastMessage?.createdAt || node?.createdAt || new Date()
              )
            )}
          </span>
          <Button
            buttonStyle="none"
            className="w-40 !p-0 flex justify-center items-center text-text-action-inverse"
            icon="Trash"
            iconClassName="w-24 h-24"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteThread(threadEdge);
            }}
          />
        </div>
      </div>
      <animated.div
        {...bind()}
        className={tw(className.swipeElement, borderRadius)}
        data-testid="thread-list-item-swipeable"
        style={{ x: pos === 0 ? 0 : x }}
      >
        {children}
      </animated.div>
    </div>
  );
};

export default AIThread;
