import { Pill } from "components/design-system/Pill";
import { useLinksCountsQuery } from "generated/graphql";
import tw from "utils/tw";
import Files from "./Files";
import Links from "./Links";
import Media from "./Media";
import SharedEmptyState from "./SharedEmptyState";
import { useSharedFilterState } from "./providers/SharedFilterProvider";
import { FiltersKeys, SharedFilters } from "./types";

const Shared = ({ isWide, groupID }: { isWide: boolean; groupID: string }) => {
  const { filter, setState } = useSharedFilterState(({ filter }) => ({
    filter,
  }));

  const { data } = useLinksCountsQuery({
    variables: { recipientIDs: [groupID] },
    fetchPolicy: "cache-and-network",
  });

  const totalCount =
    (data?.files.totalCount ?? 0) +
    (data?.media.totalCount ?? 0) +
    (data?.links.totalCount ?? 0);

  const counts = {
    [SharedFilters.Files]: data?.files.totalCount,
    [SharedFilters.Media]: data?.media.totalCount,
    [SharedFilters.Links]: data?.links.totalCount,
  };

  const setFilter = (filter: FiltersKeys) => setState({ filter });

  return (
    <div className="flex flex-col grow min-w-0 max-w-[832px] py-16 gap-8">
      <ul
        className={tw(
          "flex items-center h-52 p-12 gap-8 bg-background-body shadow-level1",
          { "rounded-lg": isWide }
        )}
      >
        {Object.values(SharedFilters).map(pill => (
          <Pill
            key={pill}
            name={pill}
            onClick={setFilter}
            selected={filter === pill}
            count={pill !== SharedFilters.Recent ? counts[pill] : undefined}
          />
        ))}
      </ul>
      <div className="flex flex-col gap-8 pb-16">
        {!data || totalCount > 0 ? (
          <>
            <Files
              groupID={groupID}
              isWide={isWide}
              filter={filter}
              openDetailedView={() => setFilter(SharedFilters.Files)}
            />
            <Media
              groupID={groupID}
              isWide={isWide}
              filter={filter}
              openDetailedView={() => setFilter(SharedFilters.Media)}
            />
            <Links
              groupID={groupID}
              isWide={isWide}
              filter={filter}
              openDetailedView={() => setFilter(SharedFilters.Links)}
            />
          </>
        ) : (
          <SharedEmptyState filter={filter} />
        )}
      </div>
    </div>
  );
};

export default Shared;
