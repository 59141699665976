import {
  ComponentProps,
  PointerEvent as ReactPointerEvent,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";

import { Flipper } from "react-flip-toolkit";
import useInfiniteScroll from "react-infinite-scroll-hook";

import {
  GroupEdge,
  Mailbox,
  ThreadEdgeSimple,
  UserEdge,
  WorkspaceEdge,
} from "@utility-types";
import analytics from "analytics";
import { ThreadListItemSelected } from "analytics/events/thread";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import BulkEditActionBar from "components/design-system/ui/BulkEditActionBar";
import { isMockChatEdge } from "components/thread/ThreadList/data/mockChatEdge";
import useListAutoSelect from "components/thread/ThreadList/hooks/useListAutoSelect";
import useThreadSelection, {
  ThreadBulkEditData,
} from "components/thread/ThreadList/hooks/useThreadSelection";
import useThreadListData, {
  ThreadListData,
} from "components/threads-list/hooks/useThreadListData";
import useElementHasTabKeyFocus from "hooks/useElementHasTabKeyFocus";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import usePrevious from "hooks/usePrevious";
import useStatusTapScrollToTop from "hooks/useStatusTapScrollToTop";
import tw from "utils/tw";

import FindThread from "components/FindThread";
import Icon from "components/design-system/icons/Icon";
import { routeToThread, useRouteParams } from "components/routing/utils";

import { flipSimultaneousTransition } from "components/Animated/utils";
import EmptyList from "components/thread/EmptyList";
import useThreadListSearch from "components/views/search/hooks/useThreadListSearch";
import useAppStateStore from "store/useAppStateStore";
import SortThreadListButton from "./SortThreadListButton";
import ThreadListItem from "./ThreadListItem/ThreadListItem";

type LongPressEvent<Target = Element> = ReactPointerEvent<Target>;

type Props = {
  canArchive?: boolean;
  canRemind?: boolean;
  compact?: ComponentProps<typeof ThreadListItem>["compact"];
  disableBulkSelect?: boolean;
  excludeChats?: boolean;
  excludeStarred?: boolean;
  headerType?: "recent" | "search"; // "recent" and "search" include bulk select ui
  listClassName?: string;
  mailbox: Mailbox;
  recipient?: GroupEdge | UserEdge | WorkspaceEdge;
  recipientID?: string;
  selectedID?: string;
  showRecentHeader?: boolean;
  showSearch?: boolean;
  skipAutoSelect?: boolean;
};

const findThreadByIndex = (
  reversedThreadEdges: ThreadEdgeSimple[],
  index?: string
) =>
  index
    ? reversedThreadEdges?.findIndex(listEdge => listEdge.node.id === index)
    : -1;

export const ThreadList = ({
  canArchive = false,
  canRemind = false,
  compact,
  disableBulkSelect,
  headerType,
  listClassName,
  mailbox,
  recipient,
  recipientID,
  selectedID,
  skipAutoSelect,
  threadBulkEditData,
  threadListData,
}: Props & {
  threadBulkEditData: ThreadBulkEditData;
  threadListData: ThreadListData;
}): JSX.Element => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const listRef = useRef<HTMLOListElement>();

  const routeParams = useRouteParams();

  const [selectedItemElement, setSelectedItemElement] =
    useState<HTMLDivElement | null>(null);

  const [swipedOpenItemId, setSwipedOpenItemId] = useState<string>();

  const { focused: focusedThreadList, ...bindElementFocus } =
    useElementHasTabKeyFocus();

  const {
    focused: focusedThreadListSortButton,
    ...bindElementFocusSortButton
  } = useElementHasTabKeyFocus();

  const prevSelectedID = usePrevious(
    focusedThreadList ? selectedID : undefined
  );

  // Thread list data

  const {
    hasNextPage,
    loadNextPage,
    pagingReset,
    result: { error, loading, threadEdges, totalCount },
  } = threadListData;

  const reversedThreadEdges = useMemo(() => {
    return threadEdges?.slice().reverse();
  }, [threadEdges]);

  const { selectedItem, setSelectedIndex } = useMenuKeyboardShortcuts({
    data: focusedThreadList ? reversedThreadEdges : undefined,
    isInfiniteList: true,
    onSelectItem: item => navigateToThreadID(undefined, item.node.id),
    scrollProps: { behavior: "smooth", block: "center" },
    selectedItemRef: selectedItemElement,
    selectItemOnTabPress: false,
  });

  const { foundThreads, isFindingThreads, searching, setMatch } =
    useThreadListSearch(mailbox);

  // Bulk edit

  const {
    isSelected: isBulkSelected,
    clearExcludedIDs,
    selection,
    setSelectMode: setBulkSelectMode,
    toggleEdgeSelected,
  } = threadBulkEditData;

  // Thread navigation

  const history = useHistory();

  const navigateToThreadID = (
    e?: PointerEvent | LongPressEvent,
    threadID?: string,
    replace?: boolean
  ) => {
    if (!threadID) return;

    const to = e?.ctrlKey || e?.metaKey ? "secondary" : "primary";

    const path = routeToThread({ threadID, to });
    replace ? history.replace(path) : history.push(path);
  };

  useListAutoSelect({
    autoSelect: routeParams.threadID === undefined,
    edges: threadEdges ?? [],
    selectedID,
    selectID: id => navigateToThreadID(undefined, id, true),
    skip: skipAutoSelect,
  });

  // List scrolling / swiping

  useStatusTapScrollToTop(listRef);

  useEffect(() => {
    const list = listRef.current;
    if (!list || !swipedOpenItemId) return;

    const prevTop = list.scrollTop;
    const handler = () => {
      if (!listRef.current) return;

      // don't close swiped open item if scrolled less than 30px
      if (Math.abs(prevTop - listRef.current.scrollTop) < 30) return;
      setSwipedOpenItemId(undefined);
    };

    list.addEventListener("scroll", handler);

    return () => {
      list.removeEventListener("scroll", handler);
    };
  }, [swipedOpenItemId]);

  const [scrollSentryRef, { rootRef: scrollListRef }] = useInfiniteScroll({
    disabled: !!error,
    hasNextPage,
    loading,
    onLoadMore: loadNextPage,
    rootMargin: "0px 0px 200px 0px",
  });

  const flipperListRef = useCallback<RefCallback<Flipper>>(
    (ref: Flipper | null) => {
      // hacky way to get at the private el ref from Flipper
      const listEl = (ref as unknown as { el?: HTMLOListElement } | null)?.el;
      if (listEl === listRef.current) return;
      listRef.current = listEl;
      scrollListRef(listRef.current);
    },
    [scrollListRef]
  );

  const mapThreadEdgeToItem = (edge: ThreadEdgeSimple) => {
    const isMockChat = isMockChatEdge(edge);
    const itemBulkMode =
      disableBulkSelect || isMockChat
        ? undefined
        : selection
          ? isBulkSelected(edge)
            ? "selected"
            : "unselected"
          : "default";
    return (
      <ThreadListItem
        key={edge.node.id}
        ref={setSelectedItemElement}
        canRemind={canRemind}
        canArchive={canArchive}
        avatarComponent={
          !disableBulkSelect ? <Icon icon="Checkbox" size={20} /> : null
        }
        compact={compact}
        isSelected={
          !selection &&
          (edge.node.id === selectedID ||
            edge.node.id === selectedItem?.node.id)
        }
        item={edge}
        itemBulkMode={itemBulkMode}
        onClick={(e, edge, selecting) => {
          if (!edge || edge.__typename !== "ThreadEdge") return;

          if (
            !isMockChatEdge(edge) &&
            itemBulkMode &&
            (selecting || e.shiftKey)
          ) {
            toggleEdgeSelected(edge, e.shiftKey);
            setSwipedOpenItemId(undefined);
            return;
          }

          navigateToThreadID(e, edge.node.id);

          const listIndex =
            (reversedThreadEdges &&
              findThreadByIndex(reversedThreadEdges, edge.node.id)) ||
            0;
          analytics.track(ThreadListItemSelected, {
            listIndex,
            threadId: edge.node.id,
          });
        }}
        setSwipedOpenItemId={
          routeParams.recipientID ? undefined : setSwipedOpenItemId
        }
        swipedOpenItemId={swipedOpenItemId}
        recipientID={recipientID}
      />
    );
  };

  const skeletonItems = useMemo(
    () =>
      cloneElementForSkeletons(
        <div className="text-icon-subtle">
          <ThreadListItem
            avatarComponent={
              breakpointMD && !disableBulkSelect ? (
                <Icon icon="Checkbox" size={20} strokeWidth={2} />
              ) : null
            }
          />
        </div>,
        10
      ),
    [breakpointMD, disableBulkSelect]
  );

  const bulkEditActions = selection && (
    <BulkEditActionBar
      canArchive={canArchive}
      selection={selection}
      clearExcludedIDs={clearExcludedIDs}
      setSelectMode={setBulkSelectMode}
      totalCount={totalCount || 0}
    />
  );

  const hasThreads = (reversedThreadEdges?.length || 0) > 0;

  const shownThreadEdges = isFindingThreads
    ? foundThreads
    : reversedThreadEdges;

  useEffect(() => {
    if (selectedID === prevSelectedID) return;
    if (!reversedThreadEdges) return;

    setSelectedIndex(findThreadByIndex(reversedThreadEdges, selectedID));
  }, [
    focusedThreadList,
    selectedID,
    reversedThreadEdges,
    setSelectedIndex,
    prevSelectedID,
  ]);

  return (
    <>
      {headerType === "recent" && (
        <div
          className={tw(
            "bg-background-body shrink-0 sticky text-text-subtle -top-16",
            "border-b-1 border-background-subtle",
            "flex justify-end px-10",
            "md:rounded-t-lg"
          )}
        >
          <SortThreadListButton
            mailbox={mailbox}
            recipientID={recipientID}
            {...bindElementFocusSortButton}
          />
        </div>
      )}

      <div
        className="grow min-h-0 outline-none relative"
        tabIndex={hasThreads ? 0 : -1}
        {...bindElementFocus}
        onKeyDown={e => {
          if (["ArrowUp", "ArrowDown"].includes(e.code)) {
            e.preventDefault();
          }
        }}
      >
        <Flipper
          key={mailbox + recipientID + (pagingReset ? "-reset" : "")}
          ref={flipperListRef}
          className={tw(
            "h-full overflow-x-hidden overflow-y-auto relative",
            "translate-x-0", // fix Safari bug: https://github.com/PolymerElements/iron-list/issues/18#issuecomment-329519210
            {
              "[&>li]:!border-x-accent-highlight [&>li:first-child]:!border-t-accent-highlight":
                focusedThreadList && !focusedThreadListSortButton,
            },
            listClassName
          )}
          element="ol"
          flipKey={
            isFindingThreads
              ? false
              : shownThreadEdges?.map(i => i?.node.id).join()
          }
          handleEnterUpdateDelete={flipSimultaneousTransition}
        >
          {headerType === "search" && (
            <li
              className={tw(
                "flex items-center justify-between",
                "bg-background-app h-48",
                bulkEditActions ? "sticky top-0 z-1" : ""
              )}
            >
              {breakpointMD && bulkEditActions ? (
                <div
                  className="px-12 w-full"
                  data-testid="bulk-select-actions-for-desktop"
                >
                  {bulkEditActions}
                </div>
              ) : (
                <FindThread className="grow px-20 py-8" onChange={setMatch} />
              )}
            </li>
          )}

          {!shownThreadEdges ? (
            skeletonItems
          ) : shownThreadEdges.length === 0 && !isFindingThreads ? (
            <EmptyList mailbox={mailbox} recipient={recipient} />
          ) : (
            shownThreadEdges.map(edge => mapThreadEdgeToItem(edge))
          )}

          {searching && skeletonItems[0]}

          {hasNextPage && (
            <div ref={scrollSentryRef}>
              {loading && skeletonItems.slice(0, 5)}
            </div>
          )}
        </Flipper>

        {!breakpointMD && bulkEditActions && (
          <div
            className="absolute bottom-12 inset-x-16 z-3"
            data-testid="bulk-select-actions-for-mobile"
          >
            {bulkEditActions}
          </div>
        )}
      </div>
    </>
  );
};

const DefaultThreadList = (props: Props) => {
  const { excludeChats, excludeStarred, mailbox, recipientID } = props;

  const pageSize = Math.ceil(window.innerHeight / 66);

  const threadListData = useThreadListData({
    excludeChats,
    excludeStarred,
    mailbox,
    pageSize,
    recipientID,
  });

  const threadBulkEditData = useThreadSelection({
    excludeChats,
    mailbox,
    recipientID,
    threadEdges: threadListData?.result.threadEdges || [],
  });

  return (
    <ThreadList
      threadBulkEditData={threadBulkEditData}
      threadListData={threadListData}
      {...props}
    />
  );
};

export default DefaultThreadList;
