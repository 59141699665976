import Toast from "components/design-system/ui/ToastKit/Toast";
import { RoutingPartition } from "components/routing/RoutingPartition";
import { useRoutePartition } from "components/routing/utils";
import ActivityMobile from "components/views/activity/ActivityMobile";
import AIMobile from "components/views/ai/AIMobile";
import DirectMessagesMobile from "components/views/dms/DirectMessagesMobile";
import FeedViewMobile from "components/views/feed/FeedViewMobile";
import GroupsMobile from "components/views/groups/GroupsMobile";
import InboxMobile from "components/views/inbox/InboxMobile";
import SearchMobile from "components/views/search/SearchMobile";
import { useState } from "react";
import AppTabBar from "./AppTabBar";

const partitionClassName = "flex grow w-full min-h-0";

const AppLayoutMobile = () => {
  const { superTab } = useRoutePartition();
  const [scrollRef, setScrollRef] = useState<
    HTMLDivElement | HTMLUListElement | null
  >(null);

  return (
    <div className="bg-background-body flex flex-col grow min-h-0 max-h-dvh">
      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "inbox" || superTab === "threads"}
      >
        <InboxMobile scrollRef={scrollRef} setScrollRef={setScrollRef} />
      </RoutingPartition>

      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "ai"}
      >
        <AIMobile scrollRef={scrollRef} setScrollRef={setScrollRef} />
      </RoutingPartition>

      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "feed"}
      >
        <FeedViewMobile scrollRef={setScrollRef} />
      </RoutingPartition>

      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "groups"}
      >
        <GroupsMobile scrollRef={setScrollRef} />
      </RoutingPartition>

      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "dms"}
      >
        <DirectMessagesMobile scrollRef={setScrollRef} />
      </RoutingPartition>

      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "activity"}
      >
        <ActivityMobile scrollRef={setScrollRef} />
      </RoutingPartition>

      <RoutingPartition
        className={partitionClassName}
        isActive={superTab === "search"}
      >
        <SearchMobile />
      </RoutingPartition>

      <Toast />

      <AppTabBar scrollRef={scrollRef} />
    </div>
  );
};

export default AppLayoutMobile;
