import { useCallback } from "react";

import { ObservableQuery } from "@apollo/client";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { Workspace } from "@utility-types";

import ListHeaderWithAction from "components/ListHeaderWithAction";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import { Button } from "components/design-system/Button";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";

import { FetchWorkspaceOrPreviewEdgeQuery } from "generated/graphql";
import useModalStore from "store/useModalStore";
import getRandomInt from "utils/getRandomInt";

import { useSnackbar } from "providers/SnackbarProvider";
import WorkspaceGroupItem from "./groups/WorkspaceGroupItem";

type WorkspaceGroupsProps = {
  fetchMore: ObservableQuery<FetchWorkspaceOrPreviewEdgeQuery>["fetchMore"];
  hasError: boolean;
  isLoading: boolean;
  workspace: Workspace;
};

const WorkspaceGroups = ({
  fetchMore,
  hasError,
  isLoading,
  workspace,
}: WorkspaceGroupsProps): JSX.Element => {
  const { openSnackbar } = useSnackbar();

  const { hasNextPage, endCursor } = workspace.groups.pageInfo;

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openCreateGroupModal = useCallback(() => {
    if (!workspace) return;
    openModal(<CreateGroupModal workspaceID={workspace.id} />);
  }, [openModal, workspace]);

  const [loadMoreRef] = useInfiniteScroll({
    disabled: hasError,
    hasNextPage,
    loading: isLoading,
    onLoadMore: () => {
      fetchMore({
        variables: { groupsAfter: endCursor },
      }).catch(err => {
        openSnackbar("error", err.message);
      });
    },
    rootMargin: "0px 0px 400px 0px",
  });

  const totalGroups = workspace?.groups?.totalCount;

  const skeletons = (
    <div className="flex flex-col justify-center items-start">
      {cloneElementForSkeletons(
        <div className="flex items-center mt-20">
          <div className="flex flex-col">
            <Skeleton width={`${getRandomInt(200, 250)}px`} />
            <Skeleton width={`${getRandomInt(100, 200)}px`} />
          </div>
          <Skeleton className="ml-100" width={`${getRandomInt(50, 100)}px`} />
        </div>,
        5
      )}
    </div>
  );

  return (
    <div className="native:pb-safe-area overflow-y-auto py-16">
      <ListHeaderWithAction
        button={
          workspace && (
            <Button
              buttonStyle="simplePrimary"
              buttonType="text"
              icon="Plus"
              iconClassName="mr-3"
              iconSize={15}
              iconStroke={3}
              onClick={openCreateGroupModal}
            >
              Create new group
            </Button>
          )
        }
      >
        <div className="text-base font-bold">
          {workspace ? (totalGroups || 0) + 1 : <Skeleton width="15px" />}
          &nbsp;groups
        </div>
      </ListHeaderWithAction>

      {workspace ? (
        <ul className="space-y-16">
          <WorkspaceGroupItem group={workspace} />
          {workspace.groups.edges.map(group => (
            <WorkspaceGroupItem key={group.node.id} group={group.node} />
          ))}
        </ul>
      ) : (
        skeletons
      )}

      {hasNextPage && (
        <div ref={loadMoreRef} className="mt-10 text-text-subtle">
          Loading…
        </div>
      )}
    </div>
  );
};

export default WorkspaceGroups;
