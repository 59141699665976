/**
 * The DIV element cannot change its `display: -webkit-box` value,
 * e.g. cannot be `block`, `flex`, `inline-flex`, etc., else the `line-clamp` will not work;
 * creating a wrapper component for documentation and enforcement.
 */
const LineClamp = ({
  children,
  lineClamp = "line-clamp-2",
}: WithChildren<{
  lineClamp?:
    | `line-clamp-${number}`
    | `line-clamp-[${number}]`
    | "line-clamp-none";
}>) => <div className={lineClamp}>{children}</div>;

export default LineClamp;
