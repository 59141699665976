import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";

import {
  ReactExtensions,
  useKeymap,
  useRemirrorContext,
} from "@remirror/react";
import { useDebouncedCallback } from "use-debounce";

import { useEditorContentState } from "components/MessageEditor/providers/EditorContentProvider";
import { EditorProps } from "components/MessageEditor/types";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import tw from "utils/tw";

import { ExtensionPriority } from "remirror";
import { GlueWysiwygPreset } from "../../remirror-extensions/GlueWysiwyg";
import { hasEditorTextContent } from "../../utils";

type Props = Pick<EditorProps, "submitForm"> & {
  enterKeyBehavior?: "new-line" | "send-message";
  testId?: string;
};
const ATTACHMENT_HEIGHT = 5.875;

/**
 * Placeholder where the ProseMirror editor will be rendered.
 */
const ProseMirrorWrapper = ({
  enterKeyBehavior,
  submitForm,
  testId,
}: Props): JSX.Element => {
  const context = useRemirrorContext<ReactExtensions<GlueWysiwygPreset>>();
  const { getRootProps, getState, manager } = context;
  const { hasUploads, isProcessing, readOnly } = useEditorContentState(
    ({ hasUploads, isProcessing, readOnly }) => ({
      hasUploads,
      isProcessing,
      readOnly,
    })
  );

  const ElementRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(0);

  /* istanbul ignore getEditorHeight */
  const getEditorHeight = useDebouncedCallback(() => {
    const isDragBodyActive = document.body.classList.contains("drag-active");

    setHeight(state => {
      if (!ElementRef.current) return state;
      if (isDragBodyActive) return state;
      return ElementRef.current.offsetHeight * 0.06;
    });
  }, 1000);

  /* istanbul ignore useEffect */
  useEffect(() => {
    if (!ElementRef.current) return;

    if (manager.mounted && height) return;
    // get initial height once prose mirror has initialized
    setHeight(ElementRef.current.offsetHeight * 0.06);
  }, [height, manager.mounted]);

  const handleEnterKey = useCallback(
    ({ next }: { next: () => boolean }) => {
      if (!hasEditorTextContent(getState()) && !hasUploads) return next();

      if (isProcessing) return next();

      useLocalSettingsStore.setState({
        hasSentMessageViaCMDEnter: true,
      });

      submitForm();

      return true;
    },
    [getState, hasUploads, isProcessing, submitForm]
  );

  useKeymap(
    enterKeyBehavior === "new-line" ? "Mod-Enter" : "Enter",
    handleEnterKey,
    ExtensionPriority.Low
  );

  return (
    <div
      className={tw(
        "flex overflow-auto relative flex-col grow cursor-text",
        "drag:h-auto min-h-0 max-h-full"
      )}
      onKeyUp={() => {
        getEditorHeight();
      }}
      spellCheck="true"
      style={
        {
          "--attachmentHeight":
            readOnly || hasUploads || ATTACHMENT_HEIGHT < height
              ? "0"
              : `${ATTACHMENT_HEIGHT + height}rem`,
        } as CSSProperties
      }
      data-testid={testId}
      {...getRootProps({ ref: ElementRef })}
    />
  );
};

export default ProseMirrorWrapper;
