import {
  ComponentProps,
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { matchPath, useHistory } from "react-router";
import { useAbortController } from "use-abort-controller-hook";

import { Message, Recipient, Thread, nodeAs } from "@utility-types";
import { Addable, useFetchDraftQuery } from "generated/graphql";
import tw from "utils/tw";

import { MessageEditor } from "components/MessageEditor";
import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { useValidateMessage } from "components/thread/hooks";

import useReadOnlyEditorState from "hooks/editor/useReadOnlyEditorState";
import useAuthData from "hooks/useAuthData";
import useElementBreakpoint from "hooks/useElementBreakpoint";
import usePreviousRef from "hooks/usePreviousRef";
import useModalStore from "store/useModalStore";

import {
  PathMatch,
  currentPathWithoutDrawer,
  routePath,
  routeToThread,
  useRouteParams,
} from "components/routing/utils";

import { generateRandomId } from "stream-chat-react";
import env from "utils/processEnv";
import useRestoreDraft from "../hooks/useRestoreDraft";
import { formToInput } from "./DraftReducer";
import ThreadComposeEditor from "./ThreadComposeEditor";
import ThreadComposeHeader from "./ThreadComposeHeader";
import ThreadPromptSuggestions from "./ThreadPromptSuggestions";
import ThreadRecipientSuggestions from "./ThreadRecipientSuggestions";
import { useDraftActions } from "./hooks";
import { DraftForm } from "./types";

type Props = {
  draftID?: string;
  isModal?: boolean;
  replyToMessage?: Pick<Message, "id" | "threadID">;
  secondaryPane?: boolean;
  variant?: ComponentProps<typeof ThreadComposeEditor>["variant"];
} & Parameters<typeof useDraftActions>[0];

const ThreadComposeInner = ({
  draftID,
  initialDraft,
  isModal = false,
  replyToMessage,
  secondaryPane = false,
  variant,
}: Props): JSX.Element => {
  const { authReady } = useAuthData();
  const { d, recipientID, superTab, t, threadID } = useRouteParams();
  const history = useHistory();

  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const formRef = useRef<HTMLFormElement | null>(null);
  const breakpoint = useElementBreakpoint(formRef.current, 375);
  const { validateDraft } = useValidateMessage();

  const isPrimary = !isModal && !secondaryPane;
  const [lastMention, setLastMention] = useState<Recipient>();
  const [saveStatus, setSaveStatus] = useState("");

  useReadOnlyEditorState(editor, isModal);

  const { closeModal, modalIsOpen } = useModalStore(
    ({ closeModal, modalIsOpen }) => ({
      closeModal,
      modalIsOpen,
    })
  );

  const handleClose = useCallback(() => {
    if (isModal) {
      closeModal();
    } else if (secondaryPane) {
      history.push(currentPathWithoutDrawer());
    }
  }, [closeModal, history, isModal, secondaryPane]);

  const onFinish = useCallback(
    (result?: Thread | "deleted") => {
      if (!result) {
        handleClose();
        return;
      }

      if (result === "deleted") {
        if (isModal) {
          handleClose();
        }

        // when the draft is in the compose modal we don't need to redirect
        // modals are outside the superTab context so superTab is undefined
        if (!isModal) {
          // if we have a non-draft thread open, we want to close the draft and keep the thread
          const openThread = [d, threadID].filter(
            id => !!id && id !== draftID
          )[0];
          history.replace(
            routePath({
              superTab: superTab || "inbox",
              threadID: openThread,
            })
          );
        }
        return;
      }

      const inFeed =
        (recipientID &&
          t === "Feed" &&
          result.recipients.edges.find(e => e.node.id === recipientID)) ||
        matchPath(history.location.pathname, PathMatch.feed);

      if (inFeed) {
        handleClose();
        return;
      }

      history.push(
        routeToThread({
          threadID: result.id,
          to: secondaryPane || isModal ? "secondary" : "primary",
        })
      );
    },
    [
      d,
      draftID,
      handleClose,
      history,
      isModal,
      recipientID,
      secondaryPane,
      superTab,
      t,
      threadID,
    ]
  );

  const onSave = useCallback(
    (draftID: string) => {
      const isOpen = [d, threadID].find(id => id === draftID);
      if (isPrimary && !isOpen) {
        history.push(
          routeToThread({
            superTab: "inbox",
            threadID: draftID,
          })
        );
      }
    },
    [d, history, isPrimary, threadID]
  );

  const abortController = useAbortController();

  const { data: draftData, loading: draftLoading } = useFetchDraftQuery({
    context: {
      fetchOptions: abortController,
    },
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !draftID,
    variables: { draftID: draftID || "" },
  });

  const draft = nodeAs(draftData?.node, ["Draft"]);

  const { compose, dispatch, deleteDraft, saveDraft, sendDraft } =
    useDraftActions({
      draft,
      initialDraft: {
        replyToMessage,
        ...initialDraft,
      },
      onFinish,
      onSave,
    });

  // Callbacks

  const onAddableChange = useCallback(
    (recipientsAddable: Addable) => {
      dispatch({ type: "change", draftForm: { recipientsAddable } });
    },
    [dispatch]
  );

  const onRecipientsChange = useCallback(
    (recipients: Recipient[]) => {
      dispatch({ type: "change", draftForm: { recipients } });
      setLastMention(undefined);
    },
    [dispatch]
  );

  const onSubjectChange = useCallback(
    (subject: string) => {
      dispatch({ type: "change", draftForm: { subject } });
    },
    [dispatch]
  );

  const onMessageChange = useCallback(() => {
    if (!editor.current) return;
    const { text, attachments } = editor.current.getMessage();
    const appUnfurlSetups = editor.current.getAppUnfurlSetups();
    dispatch({
      type: "change",
      draftForm: { message: { text, attachments }, appUnfurlSetups },
    });
  }, [dispatch]);

  // Load / switch draft
  useRestoreDraft({
    draft,
    draftLoading,
    compose,
    dispatch,
    editor,
    draftID,
    onLoadStart: () => setLastMention(undefined),
  });

  // Handle draft deletion

  useEffect(() => {
    if (draftData?.node === null) {
      onFinish("deleted");
    }
  }, [draftData?.node, onFinish]);

  // Disable submit button when pending or invalid content

  const readOnly = compose.pending && compose.pending !== "save";

  const submitDisabled = useMemo(
    () =>
      readOnly ||
      !validateDraft(
        formToInput({
          ...compose.draftForm,
        }),
        "send",
        false
      ),
    [compose.draftForm, readOnly, validateDraft]
  );

  useEffect(() => {
    editor.current?.setReadOnly(readOnly);
    editor.current?.setIsProcessing(submitDisabled);
  }, [readOnly, submitDisabled]);

  // save draft on modal unmount

  const modalWasOpen = usePreviousRef(modalIsOpen());

  useEffect(
    () => () => {
      const hasClosed = modalWasOpen.current && !modalIsOpen();
      if (!hasClosed || !compose.dirty) return;
      saveDraft(true);
      saveDraft.flush();
    },
    [compose.dirty, modalIsOpen, modalWasOpen, saveDraft]
  );

  // Track save status

  const saveStartedAt = useRef(0);
  useEffect(() => {
    if (compose.pending === "save") {
      saveStartedAt.current = Date.now();
      setSaveStatus("Saving...");
      return;
    }

    if (!compose.draftID) {
      setSaveStatus("");
      return;
    }

    const timeout = setTimeout(
      () => setSaveStatus("Draft saved"),
      Math.max(0, 300 - (Date.now() - saveStartedAt.current))
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [compose.dirty, compose.draftID, compose.pending]);

  const defaultSubject = draft?.subject ?? compose.draftForm?.subject ?? "";
  const defaultRecipients =
    draft?.recipients ?? compose.draftForm.recipients ?? [];

  const isGlueAI =
    compose.draftForm.recipients.length === 1 &&
    compose.draftForm.recipients?.some(r => r.id === env.glueAIBotID);

  return (
    <Form<DraftForm>
      formRef={formRef}
      useFormProps={{
        defaultValues: {
          subject: defaultSubject,
          recipients: defaultRecipients,
        },
      }}
      className={tw(
        "relative flex flex-col px-0 h-full w-full",
        { "md:min-h-[50vh] md:max-h-[80vh]": isModal },
        { "md:min-h-[50%] md:max-h-[100%]": !isModal }
      )}
    >
      <ThreadComposeHeader
        autoSuggestSubject={!compose.draftID}
        compose={compose}
        isModal={isModal}
        onAddableChange={onAddableChange}
        onRecipientsChange={onRecipientsChange}
        onSubjectChange={onSubjectChange}
        onClose={secondaryPane ? handleClose : undefined}
        readOnly={readOnly}
      />

      <ThreadComposeEditor
        accessory={
          isGlueAI && !replyToMessage ? (
            !editor.current?.hasText() && (
              <ThreadPromptSuggestions
                onChoose={({ text, files }) => {
                  editor.current?.setMessage({
                    text,
                    attachments: [],
                  });
                  if (files) {
                    editor.current?.addAttachments(files);
                  }
                }}
              />
            )
          ) : (
            <ThreadRecipientSuggestions
              compose={compose}
              mention={lastMention}
              clearMention={() => setLastMention(undefined)}
            />
          )
        }
        autoFocus={!draftID}
        compose={compose}
        editor={editor}
        onChange={onMessageChange}
        onMention={setLastMention}
        sendDraft={sendDraft}
        variant={variant}
      />

      <div className="hidden md:flex flex-row items-center justify-between px-20 py-16 border-t-border-container select-none touch-none">
        <Button
          buttonStyle="subtle"
          className="h-40 md:h-32 -ml-12"
          icon="Trash"
          iconSize={20}
          onClick={() => deleteDraft()}
        >
          Delete draft
        </Button>
        <div className="flex items-center">
          <div
            className={tw(
              "hidden text-footnote-bold text-text-disabled mr-24",
              { "!block": breakpoint }
            )}
          >
            {saveStatus}
          </div>
          <Button
            testId="thread-compose-send-button"
            buttonStyle="primary"
            icon="Send"
            iconSize={20}
            type="submit"
            disabled={submitDisabled}
            onClick={sendDraft}
          >
            Send
          </Button>
        </div>
      </div>
    </Form>
  );
};

const ThreadCompose = (props: Props): JSX.Element => {
  // In the primary compose view, we want to use
  // the draftID to reset the form when switching
  // drafts, but we don't want to lose the state the
  // first time the draft is saved.
  // To do this we generate an ID and retain it unless
  // the user is actually switching to another draft.

  const { draftID, isModal, secondaryPane } = props;
  const isPrimary = !isModal && !secondaryPane;

  const prevDraftID = useRef(draftID);
  const stableDraftIDRef = useRef(draftID);

  const stableDraftID = useMemo(() => {
    if (isPrimary && prevDraftID.current && prevDraftID.current !== draftID) {
      // Switching drafts or resetting
      stableDraftIDRef.current = draftID;
    }

    prevDraftID.current = draftID;

    // Generate an ID if resetting
    stableDraftIDRef.current ??= generateRandomId();

    return stableDraftIDRef.current;
  }, [draftID, isPrimary]);

  return <ThreadComposeInner key={stableDraftID} {...props} />;
};

export default ThreadCompose;
