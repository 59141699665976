import { Button } from "components/design-system/Button";
import tw from "utils/tw";

/**
 * @summary Used by empty views to calculate the padding-bottom value of items in the sidebar
 * so they'll optically align with the Glue drop SVG.
 */
export const sidebarHeaderHeight = 84;

type SidebarHeaderProps = {
  buttons?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  title?: string | JSX.Element;
  onClose?: () => void;
};

const SidebarHeader = ({
  buttons,
  children,
  className,
  onClose,
  title,
}: SidebarHeaderProps) => (
  <div className={tw("border-b-1 border-border-strong", className)}>
    <div className="flex items-center justify-between py-16 px-20">
      {onClose && (
        <Button
          buttonStyle="subtle"
          buttonType="text"
          className="p-10 -ml-12 -mb-8 -mt-6 mr-4"
          icon="Close"
          iconSize={22}
          iconStroke={2}
          onClick={e => {
            e.stopPropagation();
            onClose();
          }}
        />
      )}

      {title && (
        <div className="flex items-center h-28 select-none text-title-3">
          {title}
        </div>
      )}

      <div className="flex grow items-center justify-end">{buttons}</div>
    </div>

    {children}
  </div>
);

export default SidebarHeader;
