import { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { useFormContext } from "react-hook-form";
import tw from "utils/tw";
import { State } from "./ThreadCompose/DraftReducer";
import ThreadSubjectInput from "./ThreadCompose/ThreadSubjectInput";
import useSuggestSubject, {
  minSuggestLen,
} from "./ThreadCompose/hooks/useSuggestSubject";

type Props = {
  compose: State;
  showSuggestion: boolean;
  setShowSuggestion: (show: boolean) => void;
  setSuggestionDismissed: () => void;
  onSubjectChange: (subject: string) => void;
};

const FormContent = ({
  compose,
  showSuggestion,
  setShowSuggestion,
  setSuggestionDismissed,
  onSubjectChange,
}: Props) => {
  const [autoSuggest, setAutoSuggest] = useState(
    !compose.draftID || !compose.draftForm.subject?.length
  );
  const { loadingSubject, suggestSubject } = useSuggestSubject(compose);
  const { setValue, watch, getFieldState } = useFormContext();

  const handleSuggest = useCallback(() => {
    suggestSubject?.().then(suggestion => {
      if (!suggestion?.length) return;
      setShowSuggestion(true);
      setAutoSuggest(true);
      setValue("subject", suggestion, { shouldDirty: true, shouldTouch: true });
      onSubjectChange(suggestion);
    });
  }, [onSubjectChange, setShowSuggestion, setValue, suggestSubject]);

  const messageText = compose.draftForm.message.text;
  useEffect(() => {
    if (messageText.length < minSuggestLen || !autoSuggest) return;
    handleSuggest();
  }, [messageText, handleSuggest, autoSuggest]);

  useEffect(() => {
    watch((data, { name, type }) => {
      if (type !== "change" || name !== "subject") return;
      if (!getFieldState(name).isDirty) return;
      setAutoSuggest(false);
      onSubjectChange(data[name]);
    });
  }, [getFieldState, onSubjectChange, watch]);

  const prevShowSuggestion = useRef(showSuggestion);
  useEffect(() => {
    if (prevShowSuggestion.current && !showSuggestion) {
      setValue("subject", "", { shouldDirty: false, shouldTouch: false });
    }
    prevShowSuggestion.current = showSuggestion;
  }, [showSuggestion, setValue]);

  useEffect(() => {
    if (compose.pending !== "send") return;
    setValue("subject", "", { shouldDirty: false, shouldTouch: false });
    setShowSuggestion(false);
  }, [compose.pending, setShowSuggestion, setValue]);

  return (
    <div
      className={tw(
        "pl-12 pr-16 pt-16 pb-0 flex justify-between border-b-none md:border-b-1 border-border-container",
        "md:pl-15 md:pt-6 md:pb-12 md:-ml-10 md:-mr-10"
      )}
    >
      <ThreadSubjectInput
        autoSuggest={autoSuggest}
        loadingSubject={loadingSubject}
        className="w-full"
        suggestAndSetSubject={handleSuggest}
        suggestEnabled={messageText.length >= minSuggestLen}
      />
      <Button
        buttonType="icon"
        buttonStyle="icon-secondary"
        icon="Close"
        iconSize={24}
        onClick={() => {
          setShowSuggestion(false);
          setSuggestionDismissed();
        }}
        type="button"
      />
    </div>
  );
};

const ThreadReplyHeader = ({
  compose,
  showSuggestion,
  setShowSuggestion,
  setSuggestionDismissed,
  onSubjectChange,
}: Props) => {
  return (
    <Form
      onSubmit={() => null}
      useFormProps={{ defaultValues: { subject: compose.draftForm.subject } }}
    >
      <FormContent
        compose={compose}
        showSuggestion={showSuggestion}
        setShowSuggestion={setShowSuggestion}
        setSuggestionDismissed={setSuggestionDismissed}
        onSubjectChange={onSubjectChange}
      />
    </Form>
  );
};

export default ThreadReplyHeader;
