import {
  ChatType,
  ThreadsOrder,
  usePersistentChatsQuery,
} from "generated/graphql";

import DirectMessagesList from "./DirectMessagesList";

const Recent = () => {
  const { data, fetchMore, loading } = usePersistentChatsQuery({
    fetchPolicy: "cache-first",
    variables: {
      chatType: ChatType.User,
      last: Math.ceil(window.innerHeight / 64),
      order: ThreadsOrder.LastMessage,
    },
  });

  const pageInfo = data?.persistentChats.pageInfo;

  const loadMore = () => {
    if (!pageInfo?.startCursor) return;
    fetchMore({
      variables: { before: pageInfo.startCursor },
    });
  };

  return (
    <DirectMessagesList
      data={data?.persistentChats.edges.slice().reverse()}
      hasNextPage={!!pageInfo?.hasPreviousPage}
      loadMore={loadMore}
      loading={loading}
    />
  );
};

export default Recent;
