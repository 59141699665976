import {
  DownloadAppBanner,
  MobileReminder,
} from "components/SideBar/DownloadAppQr";
import Sidebar from "components/SideBar/Sidebar";
import ComposeButton from "components/SideBar/SidebarHeader/ComposeButton";
import SidebarHeader from "components/SideBar/SidebarHeader/SidebarHeader";
import ViewScrollContainer from "components/design-system/ui/ViewScrollContainer";

import { useEducationWelcomeCard } from "components/Education";
import { useShowDownloadApp } from "components/SideBar/DownloadAppQr/hooks/useShowDownloadApp";
import { routeToView } from "components/routing/utils";
import useGroupRecipients from "hooks/useGroupRecipients";
import useOnce from "hooks/useOnce";
import { useHistory } from "react-router";
import { INBOX_SIDEBAR_ID } from "./InboxMain";
import InboxSidebarAccessories from "./InboxSidebarAccessories";
import InboxSidebarSections from "./InboxSidebarSections";

const InboxSidebarDesktop = () => {
  const groupRecipients = useGroupRecipients();
  const history = useHistory();
  const { showCampaign, onWelcomeCardViewed, onWelcomeCardDismissed } =
    useEducationWelcomeCard("inbox");
  const { showDownloadBanner } = useShowDownloadApp();

  useOnce(() => {
    onWelcomeCardViewed();
  });

  return (
    <Sidebar id={INBOX_SIDEBAR_ID}>
      <SidebarHeader
        buttons={
          <>
            <MobileReminder />
            <ComposeButton
              onClick={() =>
                history.push(routeToView({ view: "compose" }), {
                  recipients: groupRecipients,
                })
              }
            />
          </>
        }
        title="Inbox"
      />

      <ViewScrollContainer
        accessories={
          <InboxSidebarAccessories
            showEducationCard={showCampaign}
            onDismissEducationCard={onWelcomeCardDismissed}
          />
        }
        className="px-8 pt-8"
      >
        <InboxSidebarSections />
      </ViewScrollContainer>
      <DownloadAppBanner show={showDownloadBanner && !showCampaign} />
    </Sidebar>
  );
};

export default InboxSidebarDesktop;
