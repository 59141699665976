import { App as CapApp } from "@capacitor/app";
import { Network } from "@capacitor/network";

import useOnce from "hooks/useOnce";
import useResizeObserver from "hooks/useResizeObserver";
import useAppStateStore from "store/useAppStateStore";
import { BREAKPOINTS } from "utils/breakpoints";
import { deviceHasMouse } from "utils/deviceHasMouse";

export const useAppStateManager = (): void => {
  // App status (active / inactive) and network status
  useOnce(() => {
    const { appStatus, setState } = useAppStateStore.getState();

    const listeners = [
      CapApp.addListener("appStateChange", ({ isActive }) => {
        setState({ appStatus: isActive ? "active" : "inactive" });
      }),
      Network.addListener("networkStatusChange", async status => {
        setState({ networkStatus: status });
      }),
    ];

    const focusHandler = (e: Event) =>
      setState({ appStatus: e.type === "focus" ? "active" : "inactive" });
    const visibilityHandler = () =>
      setState({ appStatus: !document.hidden ? "active" : "inactive" });

    const hasMouse = deviceHasMouse();
    if (hasMouse) {
      window.addEventListener("focus", focusHandler);
      window.addEventListener("blur", focusHandler);
      document.addEventListener("visibilitychange", visibilityHandler);
    }

    if (appStatus === "unknown") {
      (async () => {
        const state = await CapApp.getState();
        setState({ appStatus: state.isActive ? "active" : "inactive" });
      })();
    }

    return () => {
      listeners.forEach(async l => (await l).remove());
      if (hasMouse) {
        window.removeEventListener("focus", focusHandler);
        window.removeEventListener("blur", focusHandler);
        document.removeEventListener("visibilitychange", visibilityHandler);
      }
    };
  });

  useResizeObserver(document.body, rect => {
    useAppStateStore.setState({
      clientWidth: rect.inlineSize,
      breakpointMD: rect.inlineSize >= BREAKPOINTS.MD,
    });
  });
};

export default useAppStateManager;
