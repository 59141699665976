import { usePerformThreadActionMutation } from "generated/graphql";
import useAppsThreadActions, { WorkspaceAction } from "./useAppsThreadActions";

type Props = {
  /** The name of the action to use. */
  name: string;
  /** If undefined, the action will be unavailable. */
  threadID?: string;
};

/**
 * Allows the consumer to use a thread action with a given name. If there
 * is no action with that name available for the workspace, it will
 * not be executable.
 */
const useThreadAction = ({ threadID, name }: Props) => {
  const appsThreadActions = useAppsThreadActions({ threadID });
  const [performThreadAction, { loading }] = usePerformThreadActionMutation();

  const availableActions =
    appsThreadActions?.reduce<WorkspaceAction[]>((acc, { actions }) => {
      return [...acc, ...actions];
    }, []) ?? [];
  const action = availableActions.find(({ actionName }) => actionName === name);

  return {
    available: !!action,
    loading,
    performThreadAction: action
      ? () =>
          performThreadAction({
            variables: { input: { ...action } },
          })
      : undefined,
  };
};

export default useThreadAction;
