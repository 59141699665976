import { useHistory } from "react-router";

import { ThreadEdge } from "@utility-types";
import { shouldSummarizeThread } from "components/Summaries/Util";
import useSummarizeThread from "components/Summaries/useSummarizeThread";
import { Button } from "components/design-system/Button";
import { routeToThread } from "components/routing/utils";
import { isSubscribed } from "components/threads-list/ThreadItem/utils";
import ThreadActionMenu from "components/threads/ThreadActionMenu";
import ThreadInfo from "components/threads/ThreadHeader/ThreadInfo";
import useFetchThreadReplyTo from "hooks/thread/useFetchThreadReplyTo";
import useInboxThreadActions from "hooks/thread/useInboxThreadActions";
import tw from "utils/tw";

import PinnedButton from "components/threads/ThreadHeader/PinnedButton";
import useAppStateStore from "store/useAppStateStore";
import Summary from "../Summaries/Summary";

type Props = {
  threadEdge: ThreadEdge | undefined;
};

const FeedThreadHeader = ({ threadEdge }: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();

  const { toggleThreadSubscribed } = useInboxThreadActions();

  const replyTo = useFetchThreadReplyTo(threadEdge?.node);

  const recipients = threadEdge?.node?.recipients.edges.map(e => e.node) || [];

  const onClickNavigate = threadEdge
    ? () => {
        history.push(
          routeToThread({ threadID: threadEdge.node.id, to: "secondary" })
        );
      }
    : undefined;

  const { points, setShowSummary, showSummary, summarize, summarizing } =
    useSummarizeThread(threadEdge?.node.id ?? "");

  const showSummarizeButton =
    !!threadEdge && shouldSummarizeThread(threadEdge.node);

  return (
    <div className="flex flex-col pb-0">
      <div
        className={tw(
          "relative flex items-center overflow-hidden max-w-full min-w-full min-h-[48px]",
          "py-10 px-16 md:px-24",
          {
            "cursor-pointer": !!onClickNavigate,
          }
        )}
        onClick={onClickNavigate}
      >
        <div className="inline-flex grow min-w-0">
          <ThreadInfo
            headerType={"feed"}
            onClick={onClickNavigate}
            recipients={recipients}
            replyTo={replyTo}
            subject={threadEdge?.node.subject}
          />
        </div>
        <div className="relative flex grow pl-20 flex-shrink-0 items-center justify-end gap-8">
          {!(threadEdge?.isSeen ?? true) ? (
            <div
              className={tw(
                "m-10 hidden md:block",
                "before:block before:w-6 before:h-6 before:content-[''] before:rounded-full before:bg-background-alert"
              )}
              data-testid="unseen-marker"
            />
          ) : null}

          {threadEdge ? (
            <Button
              buttonStyle="none"
              buttonType="none"
              className={tw(
                "focus-visible-shadow h-28 rounded-md text-body",
                "justify-center !w-28 md:px-4 md:w-auto",
                "border-1 border-border-container",
                "text-icon-secondary hover:bg-background-secondary hover:text-icon-secondary-hover",
                {
                  "md:pr-8 !w-auto": breakpointMD && !isSubscribed(threadEdge),
                }
              )}
              icon={isSubscribed(threadEdge) ? "BellSmallFilled" : "BellSmall"}
              iconSize={20}
              iconClassName={tw({
                "!mr-4": breakpointMD && !isSubscribed(threadEdge),
              })}
              onClick={e => {
                e.stopPropagation();
                toggleThreadSubscribed(threadEdge);
              }}
              tooltip={
                isSubscribed(threadEdge)
                  ? "Unfollow thread to stop notifications"
                  : "Follow thread to get notifications"
              }
            >
              {breakpointMD && !isSubscribed(threadEdge) && "Follow"}
            </Button>
          ) : null}

          {threadEdge && <PinnedButton threadID={threadEdge.node.id} />}

          <ThreadActionMenu
            additionalActions={
              showSummarizeButton
                ? [
                    {
                      icon: "SparkleFilled" as const,
                      text: "Summarize thread",
                      onClick: summarize,
                    },
                  ]
                : []
            }
            threadEdge={threadEdge}
            threadContainerBreakpointMD={breakpointMD}
          />
        </div>
      </div>
      {summarizing || showSummary ? (
        <div className="mx-16 mb-12">
          <Summary
            onClose={() => setShowSummary(false)}
            points={points}
            summarizing={summarizing}
          />
        </div>
      ) : null}
    </div>
  );
};

export default FeedThreadHeader;
