import { EducationCard } from "components/Education";
import useAppStateStore from "store/useAppStateStore";

type AISidebarAccessoriesProps = {
  showCampaign: boolean;
  onWelcomeCardDismissed: () => void;
};

const AISidebarAccessories = ({
  showCampaign,
  onWelcomeCardDismissed,
}: AISidebarAccessoriesProps) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  return showCampaign && breakpointMD ? (
    <div className="absolute left-1/2 -translate-x-1/2 z-1 bottom-0 w-full pt-8 pb-16 px-16 pointer-events-none">
      <div className="mx-auto pointer-events-auto">
        <EducationCard
          illustration="SparkleFilled"
          header="Your private workspace with Glue AI"
          body="Brainstorm one-on-one with Glue AI, then add teammates when you're ready to collaborate."
          onDismiss={onWelcomeCardDismissed}
        />
      </div>
    </div>
  ) : undefined;
};

export default AISidebarAccessories;
