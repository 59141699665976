import { ComponentProps, forwardRef, useEffect, useState } from "react";
import { FieldValues, Path, PathValue, useFormContext } from "react-hook-form";

import { Placement } from "@floating-ui/react";
import Avatar from "components/design-system/Avatar/Avatar";
import { Icon } from "components/design-system/icons";
import useElementSize from "hooks/useElementSize";
import useMenuKeyboardShortcuts from "hooks/useMenuKeyboardShortcuts";
import tw from "utils/tw";
import useFloatingElement from "../FloatingUi/useFloatingElement";
import { TextInput } from "./TextInput";
import { Option, SelectProps } from "./types";

type FakeSelectProps = {
  selectedOption?: Option;
  placeholder?: string;
  isOpen: boolean;
  hasError: boolean;
  className?: string;
  mode?: "icon-only" | "default";
};

const OptionIcon = ({
  icon,
  iconSrc,
  name,
}: {
  icon?: ComponentProps<typeof Icon>["icon"];
  iconSrc?: string;
  name?: string;
}) => {
  return iconSrc !== undefined ? (
    <Avatar
      avatarURL={iconSrc}
      size="x-small"
      rounded="rounded-sm"
      name={name}
    />
  ) : icon ? (
    <Icon icon={icon} size={20} />
  ) : null;
};

const FakeSelect = forwardRef<HTMLDivElement, FakeSelectProps>(
  (
    {
      selectedOption,
      placeholder,
      isOpen,
      hasError,
      className,
      mode = "default",
    },
    ref
  ) => {
    return (
      <div
        tabIndex={0}
        ref={ref}
        className={tw(
          "flex items-center border border-border-container rounded select-none cursor-pointer h-36",
          mode === "default" ? "gap-8 p-10" : "gap-4 p-8",
          className,
          {
            "border-border-alert": hasError,
            "border-border-active": isOpen,
          }
        )}
      >
        <OptionIcon {...selectedOption} />

        {mode === "default" &&
          (selectedOption ? selectedOption.label : placeholder)}

        <Icon
          icon={isOpen ? "DropUp" : "DropDown"}
          size="20"
          className="ml-auto text-icon-secondary"
        />
      </div>
    );
  }
);

const SelectDropdown = <TFieldValues extends FieldValues>({
  name,
  error,
  placeholder,
  options,
  wrapperClassName,
  className,
  mode,
  placement = "bottom",
}: SelectProps<TFieldValues> & {
  error?: string;
  className?: string;
  mode?: "icon-only" | "default";
  placement?: Placement;
}) => {
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<TFieldValues>();
  const selectedValue = watch(name);
  const selectedOption = options.find(o => o.value === selectedValue);

  // Sets the initial value for the hidden input which contains the valuable data for the API
  useEffect(() => {
    if (selectedValue) return;
    const firstOption = options[0];
    if (!firstOption) return;
    setValue(
      name,
      firstOption.value as PathValue<TFieldValues, Path<TFieldValues>>
    );
  }, [name, options, setValue, selectedValue]);

  const { anchorProps, floatingProps, isOpen, setIsOpen } = useFloatingElement({
    strategy: "fixed",
    placement,
    openOnClick: true,
  });

  const [inputRef, { inlineSize: inputWidth }] =
    useElementSize<HTMLDivElement>();

  const floatingStyles =
    "style" in floatingProps && typeof floatingProps.style === "object"
      ? floatingProps.style
      : {};

  const handleSelectOption = (value: string) => {
    setValue(name, value as PathValue<TFieldValues, Path<TFieldValues>>, {
      shouldDirty: true,
    });
    setIsOpen(false);
  };

  const [selectedItemRef, setSelectedItemRef] = useState<HTMLDivElement | null>(
    null
  );
  const { selectedItem, setSelectedIndex } = useMenuKeyboardShortcuts({
    data: options,
    onSelectItem: o => handleSelectOption(o.value),
    selectedItemRef,
    scrollProps: { behavior: "smooth", block: "center", inline: "center" },
    isOpen,
    preventReset: true,
  });

  // Make sure the selected item index is correctly set when opening the dropdown
  useEffect(() => {
    if (!isOpen) return;
    setSelectedIndex(options.findIndex(o => o.value === selectedValue));
  }, [options, selectedValue, setSelectedIndex, isOpen]);

  return (
    <>
      <div className={wrapperClassName} {...anchorProps}>
        <FakeSelect
          ref={inputRef}
          hasError={!!errors[name] || !!error}
          className={className}
          mode={mode}
          selectedOption={selectedOption}
          isOpen={isOpen}
          placeholder={placeholder ?? "Select an item..."}
        />
        <TextInput wrapperClassName="!my-0" type="hidden" name={name} />
      </div>

      {isOpen && (
        <div
          {...floatingProps}
          className="bg-background-body border-1 border-border-container shadow-level2 rounded-md -mt-6 overflow-y-auto max-h-300"
          style={{
            width: mode === "icon-only" ? "auto" : `${inputWidth}px`,
            ...floatingStyles,
          }}
        >
          {options.map(({ value, icon, iconSrc, label }, i) => (
            <div
              ref={e =>
                selectedItem?.value === value ? setSelectedItemRef(e) : null
              }
              key={value}
              // FIXME: role="option" must be used within a `select` element
              // role="option"
              aria-selected={selectedValue === value}
              className={tw(
                "p-8 hover:bg-background-list-hover cursor-pointer flex gap-8",
                { "bg-background-list-hover": selectedItem?.value === value }
              )}
              onClick={() => {
                setSelectedIndex(i);
                handleSelectOption(value);
              }}
            >
              <OptionIcon icon={icon} iconSrc={iconSrc} name={label} />
              <span className="text-subhead">{label}</span>
              {selectedValue === value && (
                <Icon
                  className="text-icon-primary-selected ml-auto"
                  icon="Check"
                  size={20}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SelectDropdown;
