import { useState } from "react";

import { useEducationWelcomeCard } from "components/Education";
import { EducationCard } from "components/Education/EducationCard";
import Feed from "components/Feed/Feed";
import NewThreadFeedWidget from "components/design-system/ui/NewThreadFeedWidget";
import MyFeedHeader from "components/views/groups/MyFeedHeader";
import useOnce from "hooks/useOnce";

const FeedViewDesktop = () => {
  const [scrollEl, setScrollEl] = useState<HTMLDivElement | null>(null);
  const { showCampaign, onWelcomeCardViewed, onWelcomeCardDismissed } =
    useEducationWelcomeCard("feed");

  useOnce(() => {
    onWelcomeCardViewed();
  });

  return (
    <div
      ref={setScrollEl}
      className="w-full grow min-h-0 bg-background-app-secondary overflow-auto"
    >
      <div className="w-full max-w-[832px] mx-auto">
        <div className="w-full p-16">
          <MyFeedHeader edge={undefined} />
          <NewThreadFeedWidget className="my-16" />
          <Feed feedID={"groups"} scrollEl={scrollEl} />
        </div>
      </div>

      {showCampaign && (
        <div className="absolute z-1 bottom-0 left-0 min-w-1/4 max-w-[350px] pl-16 pb-16 pointer-events-none">
          <div className="pointer-events-auto">
            <EducationCard
              illustration="MyFeed"
              header="Feed"
              body="See updates beyond your inbox and follow threads to stay informed."
              onDismiss={onWelcomeCardDismissed}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedViewDesktop;
