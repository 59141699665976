import { Device as CapDevice, OperatingSystem } from "@capacitor/device";
import * as rudderAnalytics from "rudder-sdk-js";

import { Platform, getPlatform } from "utils/platform";
import env from "utils/processEnv";

import { versionInfo } from "components/App/version";
import {
  AnalyticsEvent,
  EventProperties,
  EventPropertiesOrVoid,
} from "./analyticsEvent";

const {
  debugAnalytics,
  glueAppId,
  rudderApiUrl: apiUrl,
  rudderDataPlaneUrl: dataPlaneUrl,
  rudderWriteKey: writeKey,
} = env;

if (writeKey && dataPlaneUrl && apiUrl) {
  rudderAnalytics.load(writeKey, dataPlaneUrl, {
    configUrl: apiUrl,
    integrations: { All: false },
  });
}

const resolveOptions = async () => {
  const mapOperatingSystem = (
    operatingSystem: OperatingSystem,
    platform: Platform
  ) => {
    switch (operatingSystem) {
      case "ios":
        // `operatingSystem` will be `ios` for native Mac,
        // so we have to check `platform`
        if (platform === "mac") {
          return "Mac";
        }

        return "iOS";
      case "android":
        return "Android";
      case "mac":
        return "Mac";
      case "windows":
        return "Windows";
      default:
        return "";
    }
  };

  const { operatingSystem, osVersion } = await CapDevice.getInfo();
  const platform = getPlatform();
  return {
    app: {
      build: versionInfo.buildSha,
      name: "Glue",
      namespace: glueAppId,
      version: versionInfo.version,
      ...(versionInfo.nativeVersionNumber && {
        nativeVersionNumber: versionInfo.nativeVersionNumber,
      }),
    },
    os: {
      name: mapOperatingSystem(operatingSystem, platform),
      version: osVersion,
    },
    platform,
  };
};

const debugLog = (message: string) => {
  if (debugAnalytics) {
    console.info(`Analytics debug: ${message}`);
  }
};

const page = (
  category?: string,
  name?: string,
  properties?: rudderAnalytics.apiObject
) => {
  debugLog(
    `analytics.page name: ${name}, properties: ${JSON.stringify(properties)}`
  );

  (async () => {
    const options = await resolveOptions();
    rudderAnalytics.page(category, name, properties, options);
  })();
};

const identify = (userId?: string, traits?: rudderAnalytics.apiObject) => {
  debugLog(
    `analytics.identify userId: ${userId}, traits: ${JSON.stringify(traits)}`
  );

  (async () => {
    const options = await resolveOptions();
    rudderAnalytics.identify(userId, traits, options);
  })();
};

type EventConstructor<T extends EventProperties> = {
  /**
   * To have the right type for properties here would require a circular
   * relationship between the constructor type and the constructor argument
   * type
   */

  // biome-ignore lint: no-explicit-any
  new (properties: any): AnalyticsEvent<T>;
};

function track(event: AnalyticsEvent<EventPropertiesOrVoid>): void;
function track<T extends EventConstructor<U>, U extends EventProperties>(
  event: T,
  properties: ConstructorParameters<T>[0]
): void;
function track(event: new () => AnalyticsEvent<void>): void;

function track<T extends EventConstructor<U>, U extends EventProperties>(
  arg1: AnalyticsEvent<EventPropertiesOrVoid> | T,
  arg2?: U
) {
  let event: AnalyticsEvent<EventPropertiesOrVoid>;

  /**
   * If the first argument is a function, then we have been passed a
   * constructor. Otherwise, we have been passed an event.
   */
  if (typeof arg1 === "function") {
    // This code works for both AnalyticsEvent<EventProperties>
    // and AnalyticsEvent<void>
    const constructor = arg1;
    const properties = arg2;
    event = new constructor(properties);
  } else {
    event = arg1;
  }

  debugLog(
    `analytics.track event: ${event.eventName()}, properties: ${JSON.stringify(
      event.properties
    )}`
  );

  (async () => {
    const options = await resolveOptions();

    /**
     * If this is AnalyticsEvent<void>, properties will be undefined.
     * It's also worth noting that our EventProperties object allows null where
     * rudderAnalytics.apiObject does not. The RS SDK can be used in plain JS
     * though and the documentation for this function does not indicate that
     * it will not accept null.
     *
     * https://www.rudderstack.com/docs/stream-sources/rudderstack-sdk-integration-guides/rudderstack-javascript-sdk/#track
     */
    const properties = event.properties as
      | rudderAnalytics.apiObject
      | undefined;
    rudderAnalytics.track(event.eventName(), properties, options);
  })();
}

page();

const analytics = {
  identify,
  page,
  track,
};

export default analytics;
