import { ElementRef, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useChatContext } from "stream-chat-react";
import { useAbortController } from "use-abort-controller-hook";
import { useDebouncedCallback } from "use-debounce";

import {
  GlueDefaultStreamChatGenerics,
  Recipient,
  Thread,
  nodeAs,
} from "@utility-types";
import useScrollToTimelineEnd from "components/thread/ThreadView/hooks/useScrollToTimelineEnd";
import useSendMessage from "components/thread/ThreadView/hooks/useSendMessage";
import { useThreadViewState } from "components/thread/ThreadView/provider/ThreadViewProvider";
import { useValidateMessage } from "components/thread/hooks";
import { useLayerState } from "providers/LayerProvider";
import useMessageEditorStore from "store/useMessageEditorStore";
import generateRandomId from "utils/generateRandomId";
import { isMobile } from "utils/platform";

import { MessageEditor } from "components/MessageEditor";
import MessageEditorContainer from "components/MessageEditor/MessageEditorContainer";
import { CreateThreadButton } from "components/MessageEditor/components/controls/CreateThreadButton";
import { routeToThread } from "components/routing/utils";
import {
  DraftListDocument,
  useDeleteDraftMutation,
  useFetchDraftQuery,
} from "generated/graphql";
import useCacheEvict from "hooks/state/useCacheEvict";
import useAuthData from "hooks/useAuthData";
import useGoToThreadRecent from "hooks/useGoToThreadRecent";
import useGroupRecipients from "hooks/useGroupRecipients";
import useThreadRecipients from "hooks/useThreadRecipients";
import useAppStateStore from "store/useAppStateStore";
import useDraftMessagesStore from "store/useDraftMessagesStore";
import useNativeKeyboardStore from "store/useNativeKeyboardStore";
import { useDraftActions } from "./ThreadCompose/hooks";
import ThreadReplyHeader from "./ThreadReplyHeader";
import useAppUnfurlSetupMessage from "./hooks/useAppUnfurlSetupMessage";
import useRestoreDraft from "./hooks/useRestoreDraft";

type Props = {
  onMention?: (recipient: Recipient) => void;
  onSubmit?: () => void;
};

const ThreadReply = ({ onMention, onSubmit }: Props): JSX.Element => {
  const history = useHistory();
  const { authReady } = useAuthData();
  const { evictNode } = useCacheEvict();
  const { channel, client } = useChatContext<GlueDefaultStreamChatGenerics>();
  const editor = useRef<ElementRef<typeof MessageEditor> | null>(null);
  const isEditorNull = !editor.current;
  const goToThreadRecent = useGoToThreadRecent();
  const [deleteDraftMutation] = useDeleteDraftMutation();

  const [showSuggestion, setShowSuggestion] = useState(false);
  const [suggestionDismissed, setSuggestionDismissed] = useState(false);
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));

  const {
    editorIsActive,
    threadID,
    threadPane,
    persistentChatType,
    threadWorkspaceID,
  } = useThreadViewState(
    ({
      editorIsActive,
      threadID,
      threadPane,
      persistentChatType,
      threadWorkspaceID,
    }) => ({
      editorIsActive,
      threadID,
      threadPane,
      persistentChatType,
      threadWorkspaceID,
    })
  );

  const { addDraft, getDraft, removeDraft } = useDraftMessagesStore(
    ({ addDraft, getDraft, removeDraft }) => ({
      addDraft,
      getDraft,
      removeDraft,
    })
  );
  // NOTE: Do not change this pattern threadPane + threadID + random id
  const randomEditorID = generateRandomId(`${threadPane}${threadID}`);
  const [hideEditor, setHideEditor] = useState(false);
  const { validateMessage } = useValidateMessage();
  const sendMessage = useSendMessage();
  const { sendSetupMessages } = useAppUnfurlSetupMessage();

  const [draftID, setDraftID] = useState<string | undefined>();
  const abortController = useAbortController();
  const { data: draftData, loading: draftLoading } = useFetchDraftQuery({
    context: {
      fetchOptions: abortController,
    },
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !draftID,
    variables: { draftID: draftID ?? "" },
  });

  const draft = nodeAs(draftData?.node, ["Draft"]);

  const onFinish = (thread?: Thread | "deleted") => {
    if (!thread || typeof thread === "string") return;
    dispatch({ type: "reset" });
    setDraftID(undefined);
    breakpointMD &&
      history.push(routeToThread({ threadID: thread.id, to: "secondary" }));
  };

  const { compose, dispatch, sendDraft } = useDraftActions({
    draft,
    onFinish,
  });

  useRestoreDraft({
    draft,
    draftLoading,
    compose,
    dispatch,
    editor,
    onLoadEnd: () => setShowSuggestion(true),
    draftID,
  });

  const draftIDRef = useRef<string | undefined>();
  useEffect(() => {
    if (!compose.draftID || !channel?.id) return;
    addDraft({ threadID: channel.id, draftID: compose.draftID });
    draftIDRef.current = compose.draftID;
    setDraftID(compose.draftID);
  }, [addDraft, channel?.id, compose.draftID]);

  const userHasRecentlySentMessage = () => {
    const currentTime = Date.now();
    if (!channel?.state.messages) return false;
    for (let i = channel.state.messages.length - 1; i > 0; i--) {
      const currentMessage = channel.state.messages[i];
      if (!currentMessage) return;
      //Breaks the loop once messages have more than 5 minutes since they were sent
      //so we don't iterate over all the messages, just the most recent ones
      if (
        (currentTime - new Date(currentMessage.created_at).getTime()) / 1000 >
        300
      )
        return false;
      //Breaks the loop if the user is found in the current message
      if (currentMessage?.user?.id === client?.user?.id) return true;
    }
    return false;
  };

  const groupRecipients = useGroupRecipients();
  const groupRecipientsRef = useRef(groupRecipients);
  groupRecipientsRef.current = groupRecipients;

  const threadRecipients = useThreadRecipients({
    threadID: channel?.id,
  }).recipients;
  const threadRecipientsRef = useRef(threadRecipients);
  threadRecipientsRef.current = threadRecipients;

  const onSubjectChange = useCallback(
    (subject: string) =>
      dispatch({
        type: "change",
        draftForm: {
          subject,
          recipients: groupRecipientsRef.current.length
            ? groupRecipientsRef.current
            : threadRecipientsRef.current,
        },
      }),
    [dispatch]
  );

  const updateDraft = useDebouncedCallback(
    useCallback(() => {
      if (!channel?.id || !editor.current) return;

      const message = editor.current.getMessage();
      const hasNoText = !message.text.length;
      const hasNoAttachments = !message.attachments.length;

      dispatch({ type: "change", draftForm: { message } });

      if (hasNoAttachments && hasNoText) {
        removeDraft({ threadID: channel.id });
        return;
      }
      addDraft({
        threadID: channel.id,
        message,
        draftID: !suggestionDismissed ? draftIDRef.current : undefined,
      });
    }, [addDraft, channel?.id, dispatch, removeDraft, suggestionDismissed]),
    500
  );

  const onInputChange = () => {
    if (userHasRecentlySentMessage()) channel?.keystroke();

    updateDraft();
  };

  const onFormSubmit = () => {
    if (!editor.current || !channel) return;

    const message = editor.current.getStreamMessage();
    if (!validateMessage(message)) return;

    goToThreadRecent();

    if (showSuggestion && draftID && compose.draftForm.subject?.length) {
      sendDraft();
    } else {
      const appUnfurlSetups = editor.current.getAppUnfurlSetups();
      (async () => {
        const sentMessage = await sendMessage(message);
        if (!sentMessage) return;
        sendSetupMessages(sentMessage.id, threadID, appUnfurlSetups);
      })();
    }

    setSuggestionDismissed(false);

    onSubmit?.();

    channel.id && removeDraft({ threadID: channel.id });
    !showSuggestion &&
      draftID &&
      draft &&
      deleteDraftMutation({
        refetchQueries: [DraftListDocument],
        update: c => evictNode(draft, c),
        variables: { id: draftID },
      });

    editor.current.reset();
    editor.current.focusEditor();
  };

  useScrollToTimelineEnd(editor);

  useEffect(
    () => () => {
      if (!channel || channel.disconnected || !channel.isTyping) return;

      channel?.stopTyping().catch(err => {
        // ignore deleted channel
        if (err.status !== 404) {
          console.warn("Error: stopTyping -", err);
        }
      });
    },
    [channel]
  );

  useEffect(() => {
    if (channel?.id && editor.current && getDraft(channel.id)) {
      const df = getDraft(channel.id);
      !!df?.message && editor.current.setMessage(df.message)?.focus();
      df?.draftID && setDraftID(df?.draftID);
    }
    if (!isMobile()) editor.current?.focusEditor("end");
  }, [channel?.id, getDraft]);

  const { layerIsActive } = useLayerState(({ layerIsActive }) => ({
    layerIsActive,
  }));

  useEffect(
    () =>
      useMessageEditorStore.subscribe(({ editors }) => {
        layerIsActive &&
          setHideEditor(
            [...editors.values()].filter(
              ({ editor: { mode } }) => mode === "edit"
            ).length > 0
          );
      }),
    [layerIsActive]
  );

  useEffect(() => {
    editor.current?.setReadOnly(!editorIsActive);
  }, [editorIsActive, isEditorNull]);

  const [safeAreaPadding, setSafeAreaPadding] = useState(false);
  useEffect(
    () =>
      useNativeKeyboardStore.subscribe(
        ({ keyboardHeight }) => keyboardHeight,
        keyboardHeight => {
          setSafeAreaPadding(keyboardHeight > 100);
        }
      ),
    []
  );

  const onDismissSuggestion = () => {
    editor.current?.focusEditor();
    setSuggestionDismissed(true);
    dispatch({ type: "change", draftForm: { subject: undefined } });
    if (!draftID) return;
    channel?.id && addDraft({ threadID: channel.id, draftID: undefined });
    draft &&
      deleteDraftMutation({
        refetchQueries: [DraftListDocument],
        update: c => evictNode(draft, c),
        variables: { id: draftID },
      });
  };

  return (
    <MessageEditorContainer
      hideEditor={hideEditor}
      safeAreaPadding={safeAreaPadding}
      variant="thread-reply"
    >
      {showSuggestion && !!persistentChatType && (
        <ThreadReplyHeader
          compose={compose}
          showSuggestion={showSuggestion}
          setShowSuggestion={setShowSuggestion}
          setSuggestionDismissed={onDismissSuggestion}
          onSubjectChange={onSubjectChange}
        />
      )}
      <MessageEditor
        ref={editor}
        testId="thread-reply-message-editor"
        bottomBarSections={
          !showSuggestion && !!persistentChatType
            ? [
                <CreateThreadButton
                  showSubjectSuggestion={() => {
                    editor.current?.focusEditor();
                    setShowSuggestion(true);
                    setSuggestionDismissed(false);
                  }}
                  readOnly={!!editor.current?.readOnly()}
                />,
              ]
            : []
        }
        editorId={randomEditorID}
        onChange={onInputChange}
        onMention={onMention}
        placeholder="Send a message..."
        submitForm={onFormSubmit}
        sendButtonMode={
          showSuggestion && !!draftID && compose.draftForm.subject?.length
            ? "post"
            : "send"
        }
        showThreadActions
        workspaceID={threadWorkspaceID}
      />
    </MessageEditorContainer>
  );
};

export default ThreadReply;
