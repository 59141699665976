import { last } from "lodash-es";
import { FieldValues } from "react-hook-form";
import { Icon } from "../icons";
import SelectDropdown from "./SelectDropdown";
import { SelectProps } from "./types";

const getTimeOptions = (
  fromNow?: boolean
): SelectProps<FieldValues>["options"] => {
  const options: SelectProps<FieldValues>["options"] = [];

  let initialHour = 0;
  let initialMinute = 0;

  if (fromNow) {
    const minuteIntervals = [0, 15, 30, 45];
    initialMinute =
      minuteIntervals.find(minute => minute >= new Date().getMinutes()) || 0;
    initialHour =
      new Date().getHours() +
      (new Date().getMinutes() > Number(last(minuteIntervals)) ? 1 : 0);
  }

  for (let hour = initialHour; hour < 24; hour++) {
    const startMin = hour === initialHour ? initialMinute : 0;
    for (let minute = startMin; minute < 60; minute += 15) {
      const value = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;

      const period = hour < 12 ? "AM" : "PM";
      const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
      const label = `${displayHour}:${minute.toString().padStart(2, "0")} ${period}`;

      options.push({ value, label });
    }
  }

  return options;
};

const TimePicker = <TFieldValues extends FieldValues>({
  name,
  placeholder,
  fromNow = false,
}: Omit<SelectProps<TFieldValues>, "options"> & { fromNow?: boolean }) => {
  const options = getTimeOptions(fromNow);
  return (
    <div className="relative">
      <Icon
        icon="Clock"
        className="absolute left-8 top-1/2 -translate-y-1/2 text-icon-secondary"
        size={20}
      />
      <SelectDropdown
        wrapperClassName="w-full"
        className="h-40 pl-32"
        name={name}
        placeholder={placeholder}
        options={options}
      />
    </div>
  );
};

export default TimePicker;
