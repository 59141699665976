import { RefCallback, RefObject, useEffect, useMemo, useState } from "react";

import {
  DateSeparator as DefaultDateSeparator,
  GroupStyle,
  Message,
  MessageProps,
  StreamMessage,
  getLastReceived,
  useChatContext,
  useComponentContext,
} from "stream-chat-react";
import { UserResponse } from "stream-chat/dist/types/types";

import { GlueDefaultStreamChatGenerics } from "@utility-types";
import type { FeedbackType } from "components/GlueAIFeedback/types";
import { formatFullRelativeDate } from "utils/formatDate";
import generateRandomId from "utils/generateRandomId";
import tw from "utils/tw";

import { MessageSystem } from "../../MessageSystem";
import { UnreadMarker } from "../../UnreadMarker";
import MessageFeedback from "../MessageFeedback";
import { getReadStates } from "../utils";

type MessagePropsToOmit =
  | "channel"
  | "groupStyles"
  | "initialMessage"
  | "lastReceivedId"
  | "message"
  | "readBy"
  | "threadList";

type UseMessageListElementsProps<T extends GlueDefaultStreamChatGenerics> = {
  enrichedMessages: (StreamMessage<T> & {
    feedback_type?: FeedbackType;
    isUnreadMark?: boolean;
    thread_id?: string;
  })[];
  internalMessageProps: Omit<MessageProps<T>, MessagePropsToOmit>;
  linkedMessageId?: string;
  linkedMessageRef: RefCallback<HTMLLIElement>;
  messageGroupStyles: Record<string, GroupStyle>;
  read?: Record<string, { last_read: Date; user: UserResponse<T> }>;
  returnAllReadData: boolean;
  threadList: boolean;
  unreadMarkRef: RefObject<HTMLLIElement>;
};

const useMessageListElements = <T extends GlueDefaultStreamChatGenerics>(
  props: UseMessageListElementsProps<T>
): JSX.Element[] => {
  const {
    enrichedMessages,
    internalMessageProps,
    linkedMessageId,
    linkedMessageRef,
    messageGroupStyles,
    read,
    threadList,
    unreadMarkRef,
  } = props;

  const [linkedMessageRefId, setLinkedMessageRefId] = useState<string>();

  const { client, customClasses } = useChatContext<T>();
  const { DateSeparator = DefaultDateSeparator, HeaderComponent } =
    useComponentContext<T>();

  // get the readData, but only for messages submitted by the user themselves
  const readData = useMemo(
    () =>
      getReadStates(
        enrichedMessages.filter(({ user }) => user?.id === client.userID),
        read
      ),
    [enrichedMessages, read, client.userID]
  );

  const lastReceivedId = useMemo(
    () => getLastReceived(enrichedMessages),
    [enrichedMessages]
  );

  useEffect(() => {
    const linkedMessageEl = enrichedMessages.filter(
      m => m.id === linkedMessageId
    );
    linkedMessageEl.length &&
      linkedMessageRefId !== linkedMessageId &&
      setLinkedMessageRefId(linkedMessageId);
  }, [enrichedMessages, linkedMessageId, linkedMessageRefId]);

  return useMemo(
    () =>
      enrichedMessages.map(message => {
        const groupStyles: GroupStyle = messageGroupStyles[message.id] || "";
        const messageClass =
          customClasses?.message || `str-chat-li str-chat-li--${groupStyles}`;

        const date = new Date(message.created_at || message.date || 0);
        const reactKey = message.id || date.toISOString() || generateRandomId();

        if (message.isUnreadMark) {
          return (
            <li key={reactKey} ref={unreadMarkRef}>
              <UnreadMarker />
            </li>
          );
        }

        if (message.customType === "message.date") {
          return (
            <li key={`${reactKey}-date`}>
              <DateSeparator
                date={date}
                formatDate={formatFullRelativeDate}
                unread={message.unread}
              />
            </li>
          );
        }

        if (message.customType === "channel.intro" && HeaderComponent) {
          return (
            <li key="intro">
              <HeaderComponent />
            </li>
          );
        }

        if (message.type === "system" || message.type === "deleted") {
          return (
            <li key={reactKey}>
              <MessageSystem
                groupStyle={groupStyles}
                message={message}
                readBy={readData[message.id] || []}
              />
            </li>
          );
        }

        return (
          <li
            key={reactKey}
            ref={linkedMessageId === message.id ? linkedMessageRef : null}
            className={tw(messageClass, {
              "relative pt-12 pb-4 bg-background-secondary":
                message.type === "ephemeral",
            })}
            data-message-id={message.id}
            data-testid={messageClass}
          >
            {message.type === "ephemeral" && (
              <span className="absolute top-4 left-65 text-xs font-semibold text-text-subtle">
                Only visible to you
              </span>
            )}
            <Message
              groupStyles={[groupStyles]} /* TODO: convert to simple string */
              lastReceivedId={lastReceivedId}
              message={message}
              readBy={readData[message.id] || []}
              threadList={threadList}
              {...internalMessageProps}
            />
            {message.feedback_type && (
              <div className="mr-16">
                <MessageFeedback message={message} />
              </div>
            )}
          </li>
        );
      }),
    [
      enrichedMessages,
      messageGroupStyles,
      customClasses?.message,
      HeaderComponent,
      linkedMessageId,
      linkedMessageRef,
      lastReceivedId,
      readData,
      threadList,
      internalMessageProps,
      unreadMarkRef,
      DateSeparator,
    ]
  );
};

export default useMessageListElements;
