import { uuidv4 } from "@firebase/util";
import { last } from "lodash-es";
import { StreamClientContext } from "providers/StreamClientProvider";
import { useContext, useEffect, useRef } from "react";

import { ThreadEdgeSimple } from "@utility-types";
import { useGlueAIBot } from "hooks/glueAI/useGlueAIBot";
import useEphemeralMessage from "hooks/state/useEphemeralMessage";

import useAuthData from "hooks/useAuthData";
import { env } from "utils/processEnv";

const reminderMessageText = "⏰ Here's your reminder.";

const useSendReminderMessage = ({
  threadEdge,
}: { threadEdge?: ThreadEdgeSimple }) => {
  const { authData } = useAuthData();
  const { streamClient } = useContext(StreamClientContext);
  const { postEphemeralMessage, updateEphemeralMessage } =
    useEphemeralMessage();
  const glueAIBot = useGlueAIBot();

  const reminderSent = useRef(false);
  useEffect(() => {
    if (reminderSent.current || !threadEdge) return;
    if (
      glueAIBot &&
      threadEdge?.remindAt &&
      new Date(threadEdge.remindAt) < new Date()
    ) {
      reminderSent.current = true;

      const reminderMessage = {
        createdAt: new Date(threadEdge.remindAt).toISOString(),
        id: `${authData?.me.id}-${uuidv4()}`,
        text: reminderMessageText,
        threadID: threadEdge.node.id,
        updatedAt: new Date().toISOString(),
        user: glueAIBot,
      };

      const targetChannel =
        streamClient?.activeChannels[
          `${env.streamChannelType}:${threadEdge.node.id}`
        ];
      const lastMessage = last(targetChannel?.state.messages);

      if (
        lastMessage &&
        lastMessage.text === reminderMessageText &&
        lastMessage.user?.id === glueAIBot.id
      ) {
        updateEphemeralMessage({
          ...reminderMessage,
          id: lastMessage.id,
        });
        return;
      }
      postEphemeralMessage(reminderMessage);
    }
  }, [
    authData?.me.id,
    glueAIBot,
    postEphemeralMessage,
    streamClient?.activeChannels,
    threadEdge,
    updateEphemeralMessage,
  ]);
};

export default useSendReminderMessage;
