import { useHistory } from "react-router";

import { last } from "lodash-es";

import { ThreadEdgeSimple } from "@utility-types";
import { SectionItem } from "components/design-system/ui/sections-sidebar";
import {
  currentPathWithSearch,
  locationFromRoute,
  routeToGroup,
  useRouteParams,
} from "components/routing/utils";
import { InboxThreadListItem } from "components/views/inbox/InboxMain";
import { ThreadsOrder, usePersistentChatsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useChatRecipient from "hooks/useChatRecipient";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import { unreadThreadEdges } from "utils/sumUnreadCount";

import { DropdownActionButton } from "components/design-system/FloatingUi/DropdownActionButton";
import { DropdownActionButtonGroup } from "components/design-system/FloatingUi/DropdownActionButtonGroup";
import { DropdownActions } from "components/design-system/FloatingUi/DropdownActions";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";
import { useInboxSidebarSectionsState } from "components/views/inbox/providers/InboxSidebarSectionsProvider";
import useModalStore from "store/useModalStore";
import MailboxSection from "./MailboxSection";
import { useLoadMoreOnFirstPage } from "./hooks";

const sectionKey = "GroupChats";
const initialItemCount = 5;
const pageSize = 9;

const GroupChats = () => {
  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));
  const { authData, authReady } = useAuthData();
  const { recipientID: selectedID } = useRouteParams();
  const history = useHistory();
  const { swipedOpenItemId, setState } = useInboxSidebarSectionsState(
    ({ swipedOpenItemId }) => ({
      swipedOpenItemId,
    })
  );
  const setSwipedOpenItemId = (id?: string) => {
    setState({ swipedOpenItemId: id });
  };

  const { collapsedSidebarSections } = useLocalSettingsStore(
    ({ collapsedSidebarSections }) => ({ collapsedSidebarSections })
  );
  const collapsed = collapsedSidebarSections.includes(sectionKey);
  const chatRecipient = useChatRecipient();

  const { data, fetchMore } = usePersistentChatsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: {
      last: pageSize,
      order: ThreadsOrder.Unread,
    },
  });

  const reversedChats =
    data?.persistentChats.edges
      .slice()
      .reverse()
      .filter(e => !e.isStarred) ?? [];

  const sectionItems = (edges: ThreadEdgeSimple[]) => {
    if (collapsed) {
      edges = edges.filter(edge => chatRecipient(edge.node)?.id === selectedID);
    }
    return edges?.map(edge => {
      const group = chatRecipient(edge.node);
      return (
        <SectionItem
          key={edge.node.id}
          canFollow={false}
          className="!px-0 mx-0 group/thread-list-item"
          flipId={`group-chats-${edge.node.id}`}
          onClick={(
            _e:
              | React.KeyboardEvent<HTMLDivElement>
              | React.MouseEvent<HTMLDivElement>
          ) => {
            history.push(routeToGroup({ groupID: group?.id ?? "" }));
          }}
          canArchive={false}
          setSwipedOpenItemId={setSwipedOpenItemId}
          swipedOpenItemId={swipedOpenItemId}
          itemData={edge}
          type="thread"
        >
          <InboxThreadListItem
            canFollow={false}
            icon="ChatRounded"
            isSelected={group?.id === selectedID}
            sectionIsOpen={!collapsed}
            threadID={edge?.node.id}
          />
        </SectionItem>
      );
    });
  };

  const loadNextPage = () =>
    fetchMore({
      variables: { before: data?.persistentChats.pageInfo.startCursor },
    });

  const { firstPageSize } = useLoadMoreOnFirstPage({
    loadMore:
      !!data?.persistentChats.pageInfo.hasNextPage &&
      (last(reversedChats)?.unreadMessageCounts.total ?? 0) > 0,
    loadNextPage,
    pageSize: pageSize + 1,
  });

  // Only show chats with activity in the last week
  const lastRecentIdx = reversedChats?.findLastIndex(c => {
    const lastMessageDate = new Date(
      c.node.lastMessage?.createdAt || c.node.createdAt
    );
    const diff = new Date().getTime() - lastMessageDate.getTime();
    return diff / (1000 * 60 * 60 * 24) < 7;
  });

  // Show at least 5 chats, even if not recent
  const moreCutoffMax =
    Math.min(lastRecentIdx ?? firstPageSize, firstPageSize) + 1;
  const moreCutoff = Math.max(initialItemCount, moreCutoffMax);

  const initialChats = reversedChats?.slice(0, moreCutoff);
  const moreChats = reversedChats?.slice(moreCutoff);

  return (
    <MailboxSection
      flipKey={data?.persistentChats.edges.map(e => e.node.id).join()}
      hasPreviousPage={data?.persistentChats.pageInfo.hasPreviousPage}
      moreItems={sectionItems(moreChats) ?? []}
      onClickMore={loadNextPage}
      sectionItems={sectionItems(initialChats) ?? []}
      sectionKey={sectionKey}
      sectionTitle="Groups"
      sectionTitleDropdown={
        <DropdownActions>
          <DropdownActionButtonGroup>
            <DropdownActionButton
              icon="Plus"
              onClick={() => {
                openModal(<CreateGroupModal />);
              }}
            >
              Create group
            </DropdownActionButton>
            <DropdownActionButton
              icon="Groups"
              onClick={() => {
                const { pathname, search } = locationFromRoute(
                  currentPathWithSearch({ v: "directory" })
                );
                history.push({
                  pathname,
                  search,
                });
              }}
            >
              Browse groups
            </DropdownActionButton>
          </DropdownActionButtonGroup>
        </DropdownActions>
      }
      selectedItem={
        !initialChats?.find(c => chatRecipient(c.node)?.id === selectedID)
          ? sectionItems(
              moreChats?.filter(c => chatRecipient(c.node)?.id === selectedID)
            )[0]
          : undefined
      }
      showSkeleton={!data || !authData}
      unreadCount={unreadThreadEdges(reversedChats, "total")}
      unreadMentions={unreadThreadEdges(reversedChats, "mentioned")}
    />
  );
};

export default GroupChats;
