import { EducationCard } from "components/Education/EducationCard";
import MoreUnreadButton from "./MoreUnreadButton";

type InboxSidebarAccessoriesProps = {
  showEducationCard?: boolean;
  onDismissEducationCard?: () => void;
};

const InboxSidebarAccessories = ({
  showEducationCard,
  onDismissEducationCard,
}: InboxSidebarAccessoriesProps) => {
  return (
    <>
      <MoreUnreadButton isTop />
      <div className="absolute left-1/2 -translate-x-1/2 z-1 bottom-0 w-full pt-8 pb-16 px-16 pointer-events-none">
        <div className="w-full flex flex-col gap-8 items-center">
          <div className="mx-auto pointer-events-auto">
            <MoreUnreadButton />
          </div>
          {showEducationCard && (
            <div className="mx-auto pointer-events-auto">
              <EducationCard
                illustration="Inbox"
                header="Inbox"
                body="Your personalized home for important threads and unreads. Archive threads when you're done with them and star your favorites."
                onDismiss={onDismissEducationCard}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InboxSidebarAccessories;
