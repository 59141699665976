import { EmojiListWrapper } from "components/design-system/EmojiList";
import { TextInput } from "components/design-system/Forms/TextInput";
import tw from "utils/tw";

type Props = {
  autoSuggest: boolean;
  className?: string;
  isGlueAI?: boolean;
  loadingSubject: boolean;
  readOnly?: boolean;
  suggestAndSetSubject: () => void;
  suggestEnabled: boolean;
  textInputClassName?: string;
  wrapperClassName?: string;
};

const ThreadSubjectInput = ({
  autoSuggest,
  className = "",
  isGlueAI,
  loadingSubject,
  readOnly,
  suggestAndSetSubject,
  suggestEnabled,
  textInputClassName,
  wrapperClassName = "",
}: Props) => (
  <EmojiListWrapper className={className}>
    <TextInput
      className={tw(
        "px-4 !py-0 text-headline-bold border-none bg-transparent disabled:bg-transparent truncate",
        textInputClassName
      )}
      wrapperClassName={tw("!my-0", wrapperClassName)}
      disabled={readOnly}
      name="subject"
      placeholder={isGlueAI ? "What should we work on?" : "New thread"}
      icon={isGlueAI ? undefined : "SparkleFilled"}
      iconClassName={
        loadingSubject
          ? "!text-background-magic animate-heartbeat"
          : autoSuggest && suggestEnabled
            ? "!text-background-magic"
            : suggestEnabled
              ? "!text-icon-secondary"
              : "!text-icon-disabled"
      }
      iconOnClick={suggestEnabled ? suggestAndSetSubject : undefined}
      iconPosition="trailing"
      iconSize={20}
      iconTooltip={
        suggestEnabled
          ? "Suggest a subject"
          : "Type a message to get a name suggestion"
      }
    />
  </EmojiListWrapper>
);

export default ThreadSubjectInput;
