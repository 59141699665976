import { ReactComponent as LLMGlueLogo } from "assets/llm-logo-glue.svg";
import SelectDropdown from "components/design-system/Forms/SelectDropdown";
import {
  useFetchLlmModelsQuery,
  useUpdateSettingsMutation,
  useUpdateThreadMetadataMutation,
  useUserSettingsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import glueImageURL from "utils/glueImageURL";
import tw from "utils/tw";
import { UseLlmModelResult } from "../hooks/useLlmModel";

type LlmModelOption = {
  iconSrc: string;
  label: string;
  value: string;
};

const AIModelSelect = ({
  defaultModel,
  defaultModelLoading,
  mode,
  threadID = "",
}: {
  mode?: ComponentProps<typeof SelectDropdown>["mode"];
  threadID?: string;
} & Omit<UseLlmModelResult, "modelOptions">) => {
  const [disabled, setDisabled] = useState(false);

  const { data: userSettings } = useUserSettingsQuery({
    fetchPolicy: "cache-and-network",
    skip: !!threadID,
  });

  const { getFieldState, watch } = useFormContext();
  const chatModel = watch("chatModel");

  const [updateThreadMetadata] = useUpdateThreadMetadataMutation({
    errorPolicy: "all",
  });
  const [updateSettings] = useUpdateSettingsMutation({
    errorPolicy: "all",
  });

  useEffect(() => {
    if (!getFieldState("chatModel").isDirty || !defaultModel) {
      return;
    }

    setDisabled(true);

    if (threadID) {
      const input = {
        __typename: "ThreadMetadata" as const,
        id: "",
        threadID,
        aiSettings: { __typename: "AISettings" as const, chatModel },
      };
      updateThreadMetadata({
        optimisticResponse: {
          updateThreadMetadata: input,
        },
        variables: { input },
      })
        .catch(err => {
          console.error("Error: [updateThreadMetadata] - ", err);
        })
        .finally(() => setDisabled(false));

      return;
    }

    // currently sets this globally, impacting user preferences;
    // in the future, we may want to set this per-thread.
    if (!userSettings) return;

    updateSettings({
      variables: {
        settings: {
          ...userSettings.settings,
          llmSettings: {
            ...userSettings.settings.llmSettings,
            chatModel: chatModel,
          },
        },
      },
    })
      .catch(err => {
        console.error("Error: [updateSettings] - ", err);
      })
      .finally(() => setDisabled(false));
  }, [
    chatModel,
    defaultModel,
    getFieldState,
    threadID,
    updateSettings,
    updateThreadMetadata,
    userSettings,
  ]);

  const { authData } = useAuthData();
  const workspaceID = authData?.me.workspaceIDs[0] ?? "";
  const { data: llmModelsData } = useFetchLlmModelsQuery({
    fetchPolicy: "cache-and-network",
    skip: !workspaceID,
    variables: {
      workspaceID,
    },
  });

  const modelOptions: LlmModelOption[] = useMemo(() => {
    return (
      llmModelsData?.llmModels.edges.map(({ node }) => {
        return {
          iconSrc: glueImageURL(node.providerLogoURL, {
            fit: "max",
            w: 40,
            h: 40,
          }),
          label: node.name,
          value: node.id,
        };
      }) ?? []
    );
  }, [llmModelsData?.llmModels.edges]);

  return (
    <SelectDropdown
      className={tw("!h-28", mode === "default" ? "min-w-160" : "")}
      disabled={disabled || defaultModelLoading}
      mode={mode}
      name="chatModel"
      options={[
        {
          icon: LLMGlueLogo,
          label: "Automatic",
          value: "auto",
        },
        ...modelOptions,
      ]}
      placement="bottom-end"
    />
  );
};

export default AIModelSelect;
