import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { Button } from "components/design-system/Button";
import { routeParams as getRouteParams } from "components/routing/utils";
import AutoSuggestSubjectInput from "components/threads/ThreadCompose/AutoSuggestSubjectInput";
import { State } from "components/threads/ThreadCompose/DraftReducer";
import {} from "react";
import { useHistory } from "react-router";
import useAppStateStore from "store/useAppStateStore";
import tw from "utils/tw";
import { UseLlmModelResult } from "../hooks/useLlmModel";
import AIModelSelect from "./AIModelSelect";

type Props = {
  autoSuggestSubject?: boolean;
  compose: State;
  isModal?: boolean;
  onSubjectChange?: (subject: string) => void;
  onClose?: (() => void) | undefined;
  readOnly?: boolean;
};

const AIComposeHeader = ({
  autoSuggestSubject = true,
  compose,
  defaultModel,
  isModal,
  onSubjectChange,
  onClose,
  readOnly,
}: Props & Omit<UseLlmModelResult, "modelOptions">) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();

  const { pathname, search } = history.location;
  const { d } = getRouteParams({ pathname, search });

  return (
    <div
      className="relative flex flex-col items-start shrink-0 w-full border-b border-border-container"
      data-testid="PaneHeader"
    >
      <div className="flex flex-col w-full">
        <div
          className={tw("flex items-center gap-12 w-full py-16 pr-16", {
            "pr-52": isModal, // space for close button 36 + 16
          })}
        >
          <div className="flex items-center grow min-w-0 pl-16 w-full">
            {!!onClose && (
              <Button
                buttonStyle="subtle"
                buttonType="text"
                className="px-8 mr-12"
                icon="Close"
                iconSize={20}
                iconStroke={2}
                onClick={e => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            )}
            {!isModal && <BackStackButton size="small" />}

            <div className="flex items-center w-full h-28 text-headline-bold text-text-primary">
              <AutoSuggestSubjectInput
                autoSuggestSubject={autoSuggestSubject}
                className="w-full"
                compose={compose}
                onSubjectChange={onSubjectChange}
                readOnly={readOnly}
                textInputClassName="!pl-0"
                isGlueAI
              />
            </div>
          </div>

          <div className="flex gap-8 justify-end grow-0 shrink-0 h-28 relative z-1">
            <AIModelSelect
              defaultModel={defaultModel}
              mode={!breakpointMD || d ? "icon-only" : "default"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIComposeHeader;
