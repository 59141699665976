import { useCallback, useRef, useState } from "react";

import { nodeAs } from "@utility-types";
import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import {
  MemberRole,
  useFetchWorkspaceOrPreviewEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";

import { ProfileItemHeader, StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";

import type { FormRef } from "./WorkspaceModalForm";

import NotAMemberIcons from "components/design-system/NotAMemberIcons";
import useAppStateStore from "store/useAppStateStore";
import { isNative } from "utils/platform";
import WorkspaceAdvanced from "./WorkspaceAdvanced";
import WorkspaceApps from "./WorkspaceApps";
import WorkspaceBilling from "./WorkspaceBilling";
import WorkspaceGroups from "./WorkspaceGroups";
import WorkspaceMembers from "./WorkspaceMembers";
import WorkspaceSettings from "./WorkspaceSettings";

export enum WorkspaceModalTabs { // in order of UI appearance
  Groups = "groups",
  Members = "members",
  Apps = "apps",
  Settings = "settings",
  Billing = "billing",
  Advanced = "advanced",
}

type Props = {
  defaultTab?: WorkspaceModalTabs;
  workspaceID?: string;
} & ModalProps;

const WorkspaceProfileModal = ({
  defaultTab,
  workspaceID,
  ...props
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authData, authReady } = useAuthData();

  const { data, error, fetchMore, loading } =
    useFetchWorkspaceOrPreviewEdgeQuery({
      fetchPolicy: authReady ? "cache-and-network" : "cache-only",
      nextFetchPolicy: "cache-first",
      skip: !(workspaceID && authData),
      variables: {
        id: `${workspaceID}-${authData?.me.id}`,
        membersLimit: 50,
      },
    });

  const { memberEdge, previewEdge } = useMemberEdge(
    nodeAs(data?.node, ["WorkspaceEdge", "WorkspacePreviewEdge"])
  );

  const workspaceEdge = previewEdge || memberEdge;

  const isAdmin = memberEdge?.memberRole === MemberRole.Admin;

  const tabs = [
    {
      tab: WorkspaceModalTabs.Groups,
    },
    {
      tab: WorkspaceModalTabs.Members,
    },
  ];

  if (breakpointMD) {
    tabs.push({
      tab: WorkspaceModalTabs.Apps,
    });
  }

  if (isAdmin) {
    const adminTabs = [
      { tab: WorkspaceModalTabs.Settings },
      { tab: WorkspaceModalTabs.Advanced },
    ];

    if (breakpointMD && !isNative()) {
      adminTabs.splice(1, 0, { tab: WorkspaceModalTabs.Billing });
    }

    tabs.push(...adminTabs);
  }

  const [selectedTab, setSelectedTab] = useState<WorkspaceModalTabs>(
    tabs.find(t => t.tab === defaultTab)?.tab ?? WorkspaceModalTabs.Groups
  );
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const formRef = useRef<FormRef | null>();

  const setFormRef = useCallback(
    (node: FormRef | null) => {
      formRef.current = node;
      if (!node || submitDisabled === node?.submitDisabled) return;
      setSubmitDisabled(node?.submitDisabled ?? false);
    },
    [submitDisabled]
  );

  return (
    <StandardModal
      header={
        <Header
          className="!justify-start md:h-48 md:min-h-0 md:pt-16"
          mobileCtaLabel={
            selectedTab === WorkspaceModalTabs.Settings ? "Save" : undefined
          }
          mobileCtaProps={{
            disabled: submitDisabled,
            onClick: () => {
              formRef.current?.submitForm();
            },
          }}
          variant="borderless"
        >
          <ProfileItemHeader target={{ workspace: workspaceEdge?.node }} />
        </Header>
      }
      mdHeightAuto={false}
      {...props}
    >
      <Main ref={scrollContainerRef} className="relative">
        <div className="bg-background-modal sticky top-0 z-1">
          <Tabs<WorkspaceModalTabs>
            buttonClassName="border-b-2 capitalize h-36 mr-24 z-1"
            className="px-16 md:px-32 w-full"
            onClickTab={setSelectedTab}
            selectedTab={selectedTab}
            tabs={tabs}
          />
          <div className="border-b-thin md:border-b-1 border-background-subtle relative -top-1 z-0" />
        </div>

        <div className="px-16 md:px-32">
          {previewEdge ? (
            <div className="join-group" data-testid="JoinGroup">
              <NotAMemberIcons background="bg-background-modal" />
              <span className="text-title">
                You're not a member of this workspace.
              </span>
            </div>
          ) : (
            <>
              {selectedTab === WorkspaceModalTabs.Groups && memberEdge && (
                <WorkspaceGroups
                  fetchMore={fetchMore}
                  hasError={!!error}
                  isLoading={loading}
                  workspace={memberEdge.node}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Members && memberEdge && (
                <WorkspaceMembers
                  scrollContainerRef={scrollContainerRef}
                  fetchMore={fetchMore}
                  hasError={!!error}
                  isAdmin={isAdmin}
                  modalId={props.modalId}
                  workspace={memberEdge.node}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Apps && (
                <WorkspaceApps workspaceEdge={memberEdge} />
              )}

              {selectedTab === WorkspaceModalTabs.Settings && workspaceID && (
                <WorkspaceSettings
                  ref={setFormRef}
                  loading={loading}
                  workspace={memberEdge?.node}
                  {...props}
                />
              )}

              {selectedTab === WorkspaceModalTabs.Billing && workspaceID && (
                <WorkspaceBilling workspaceID={workspaceID} />
              )}

              {selectedTab === WorkspaceModalTabs.Advanced && memberEdge && (
                <WorkspaceAdvanced workspace={memberEdge.node} />
              )}
            </>
          )}
        </div>
      </Main>
    </StandardModal>
  );
};

export default WorkspaceProfileModal;
