import { ApolloCache } from "@apollo/client";

import { Mailbox, ThreadEdgeSimple } from "@utility-types";
import { ThreadSubscription } from "generated/graphql";
import isGlueAIRecipient from "utils/thread/isGlueAIRecipient";

import {
  ThreadListCacheOp,
  ThreadListVariables,
  mutatePersistentChats,
  mutateThreadList,
} from "./threadHelpers";

export type ThreadActions = {
  archived?: boolean;
  read?: boolean;
  seen?: boolean;
  starred?: boolean;
  subscription?: ThreadSubscription;
};

type ThreadListRule = {
  // predicate function returning a cache operation
  pred: (
    edge: ThreadEdgeSimple,
    actions: ThreadActions,
    meID?: string
  ) => ThreadListCacheOp | false;
  // variables matching cached thread list KeyArgs
  vars: ThreadListVariables;
};

const isNotChat = ({ node: { isPersistentChat } }: ThreadEdgeSimple): boolean =>
  !isPersistentChat;

const inInbox = ({ archived, subscription }: ThreadActions): boolean =>
  !(archived || (!!subscription && subscription !== ThreadSubscription.Inbox));

const archiveOp = ({ archived }: ThreadActions): ThreadListCacheOp =>
  archived ? "add" : archived === false ? "rm" : "mv";

const unseenOp = ({ read, seen }: ThreadActions): ThreadListCacheOp =>
  read || seen ? "rm" : seen === false ? "add" : "mv";

const isMagic = ({ node: { recipients } }: ThreadEdgeSimple, meID?: string) =>
  isGlueAIRecipient(
    recipients.edges.filter(e => e.node.id !== meID).map(e => e.node)
  );
const aiOp = (edge: ThreadEdgeSimple, meID?: string) =>
  isMagic(edge, meID) ? "add" : "rm";

const inboxOp = ({
  archived,
  subscription,
}: ThreadActions): ThreadListCacheOp =>
  !inInbox({ archived, subscription })
    ? "rm"
    : archived === false || subscription === ThreadSubscription.Inbox
      ? "add"
      : "mv";

const inboxUnreadOp = (
  { isRead }: ThreadEdgeSimple,
  { archived, read, subscription }: ThreadActions
) =>
  read || !inInbox({ archived, subscription })
    ? "rm"
    : read === false || (archived === false && !isRead)
      ? "add"
      : "mv";

const inboxMentionedOp = (
  { isMentioned }: ThreadEdgeSimple,
  { archived, read, subscription }: ThreadActions
): ThreadListCacheOp =>
  read || !inInbox({ archived, subscription })
    ? "rm"
    : archived === false && isMentioned
      ? "add"
      : "mv";

const aiRule: ThreadListRule = {
  pred: (edge, _actions, meID) => aiOp(edge, meID),
  vars: { mailbox: Mailbox.Ai },
};

const allRule: ThreadListRule = {
  pred: (_edge, _actions) => "add",
  vars: { mailbox: Mailbox.All },
};

const unseenRule: ThreadListRule = {
  pred: (_edge, actions) => unseenOp(actions),
  vars: { mailbox: Mailbox.Unseen },
};

const inboxRule: ThreadListRule = {
  pred: (_edge, actions) => inboxOp(actions),
  vars: { mailbox: Mailbox.Inbox },
};

const inboxUnreadRule: ThreadListRule = {
  pred: (edge, actions) => inboxUnreadOp(edge, actions),
  vars: { mailbox: Mailbox.Unread },
};

const inboxMentionedRule: ThreadListRule = {
  pred: (edge, actions) => inboxMentionedOp(edge, actions),
  vars: { mailbox: Mailbox.Mentioned },
};
const archivedRule: ThreadListRule = {
  pred: (_edge, actions) => archiveOp(actions),
  vars: { mailbox: Mailbox.Archived },
};

const starredRule: ThreadListRule = {
  pred: (edge, _actions) => (edge.isStarred ? "add" : "rm"),
  vars: { mailbox: Mailbox.Starred },
};

// Cyan thread lists exclude chats
const excludeChats = (rule: ThreadListRule): ThreadListRule => ({
  pred: (edge, actions) => isNotChat(edge) && rule.pred(edge, actions),
  vars: { ...rule.vars, excludeChats: true },
});

// Cyan's Inbox "Threads" list should exclude threads that are archived or starred
const excludeChatsAndStarred = (rule: ThreadListRule): ThreadListRule =>
  excludeChats({
    pred: (edge, actions) =>
      edge.isArchived || edge.isStarred
        ? "rm"
        : actions.starred === false && rule.pred(edge, actions) === "mv"
          ? "add"
          : rule.pred(edge, actions),
    vars: { ...rule.vars, excludeStarred: true },
  });

// Rules that apply to both global and recipient thread lists
const commonThreadListRules: ThreadListRule[] = [
  allRule, // Legacy all mailbox
  excludeChats(allRule), // Cyan all mailbox
  excludeChats(unseenRule), // Cyan all unseen feed
  aiRule,
];

// Rules that apply only to global (non-recipient) thread lists
const globalThreadListRules: ThreadListRule[] = [
  inboxRule, // Legacy inbox
  excludeChats(inboxRule), // Cyan Threads inbox
  excludeChatsAndStarred(inboxRule), // Cyan For-You Following
  inboxUnreadRule, // Legacy unread
  excludeChats(inboxUnreadRule), // Cyan Threads unread
  excludeChatsAndStarred(inboxUnreadRule), // Cyan For-You unread
  inboxMentionedRule, // Legacy mentioned
  excludeChats(inboxMentionedRule), // Cyan Threads mentioned
  excludeChatsAndStarred(inboxMentionedRule), // Cyan For-You mentioned
  archivedRule, // Legacy archived
  excludeChats(archivedRule), // Cyan Threads archived
  starredRule, // Cyan For-You starred
];

export const applyThreadListRules = (
  edge: ThreadEdgeSimple,
  variables: ThreadListVariables & {
    sort?: boolean;
  },
  actions: ThreadActions = {
    archived: edge.isArchived,
    read: edge.isRead,
    seen: edge.isSeen,
    starred: edge.isStarred,
  },
  cache: ApolloCache<unknown>,
  meID: string | undefined
) => {
  const applyRules = (rules: ThreadListRule[]) => {
    for (const rule of rules) {
      const op = rule.pred(edge, actions, meID);
      if (!op) continue;
      const vars = { ...variables, ...rule.vars };
      mutateThreadList(edge, op, vars, cache);
    }
  };

  applyRules(commonThreadListRules);

  if (!variables.recipientID) {
    applyRules(globalThreadListRules);
    if (edge.node.isPersistentChat) {
      mutatePersistentChats(edge, "add", cache);
    }
  }
};
