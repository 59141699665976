// please use kebab-case for key names
type SessionStorageKeys =
  | "native-app-callback"
  | "workspace-invite-link"
  | "web-auth-state";

export const getSessionStorage = (key: SessionStorageKeys) => {
  return sessionStorage.getItem(key);
};

export const removeSessionStorage = (key: SessionStorageKeys) => {
  sessionStorage.removeItem(key);
};

export const setSessionStorage = (key: SessionStorageKeys, value: string) => {
  sessionStorage.setItem(key, value);
};
