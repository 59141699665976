import { useCallback, useMemo, useRef } from "react";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
  from,
  selectURI,
  setLogVerbosity,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import ApolloLinkTimeout from "@gluegroups/apollo-link-timeout";
import {
  LocalStorageWrapper,
  SynchronousCachePersistor,
} from "apollo3-cache-persist";

import typePolicies from "apollo/TypePolicies";
import { versionInfo } from "components/App/version";
import possibleTypes from "generated/graphql-possible-types";
import { useSnackbar } from "providers/SnackbarProvider";
import useAppStateStore from "store/useAppStateStore";
import generateRandomId from "utils/generateRandomId";
import { isNativeMobile } from "utils/platform";
import env from "utils/processEnv";

import isServerError from "../../utils/isServerError";

if (env.glueEnv === "development") {
  setLogVerbosity("debug");
}

export const useAuthedClient = (
  getAuthToken: () => Promise<string | undefined>
): {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  clearApolloCache: () => Promise<void>;
} => {
  const getAuthTokenRef =
    useRef<() => Promise<string | undefined>>(getAuthToken);
  const { closeSnackbar, openSnackbar, openType: snackbarType } = useSnackbar();

  const cachePersistor = useMemo(
    () =>
      new SynchronousCachePersistor({
        cache: new InMemoryCache({ ...possibleTypes, typePolicies }),
        maxSize: 2048 * 1024, // 2MB
        storage: new LocalStorageWrapper(window.localStorage),
      }),
    []
  );

  const apolloClient = useMemo(() => {
    const authLink = setContext(
      async (_, { headers, notifyErrors = true, ...context }) => {
        const authToken = await getAuthTokenRef.current();
        return {
          ...context,
          authenticated: !!authToken,
          clientAwareness: {
            name: "glue-web",
            version: versionInfo.fullVersionString,
          },
          headers: {
            ...(authToken ? { authorization: `Bearer ${authToken}` } : {}),
            ...headers,
          },
          notifyErrors,
        };
      }
    );

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, response }) => {
        const { authenticated, notifyErrors } = operation.getContext();
        const { appStatus } = useAppStateStore.getState();
        const shouldNotify =
          notifyErrors && (appStatus === "active" || !isNativeMobile());

        if (graphQLErrors) {
          if (!response?.data && shouldNotify) {
            openSnackbar("server", undefined, 5000);
            graphQLErrors.map(({ locations, message, path }) =>
              console.warn(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          }
        }

        if (networkError) {
          if (isServerError(networkError)) {
            if (shouldNotify) openSnackbar("server", undefined, 5000);
            console.warn(`[Server error]: ${networkError}`);
          } else if (authenticated) {
            if (shouldNotify) openSnackbar("connectivity", undefined, 5000);
            console.warn(`[Network error]: ${networkError}`);
          }

          if (isServerError(networkError) && networkError.statusCode === 401) {
            delete response?.errors;
          }
        }
      }
    );

    const sanitizeLink = new ApolloLink(
      (operation: Operation, forward: NextLink) => {
        const variables = operation.variables;

        const sanitizeValue = (value: unknown): unknown => {
          if (typeof value === "string") {
            // strip null byte and some unsafe control characters
            // explicitly allows \t, \n, \r which are meaningful
            /* biome-ignore lint: no-control-regex */
            return value.replace(/[\x00-\x08\x0E-\x1F\x7F]/g, "");
          }
          if (Array.isArray(value)) {
            return value.map(sanitizeValue);
          }
          if (value && typeof value === "object") {
            return sanitizeObject(value as Record<string, unknown>);
          }
          return value;
        };

        const sanitizeObject = (value: Record<string, unknown>) => {
          return Object.fromEntries(
            Object.entries(value).map(([k, v]) => [k, sanitizeValue(v)])
          );
        };

        operation.variables = sanitizeObject(variables);

        return forward(operation);
      }
    );

    const timeoutLink = new ApolloLinkTimeout(15000);

    const uri = `${env.glueApiUrl}/graphql`;
    const httpOpts: BatchHttpLink.Options = {
      fetch: (url, options) => {
        if (!("authorization" in (options?.headers || {}))) {
          return new Promise<Response>((_, reject) =>
            reject(new Error("Unauthenticated"))
          );
        }

        return fetch(url, { ...options, credentials: "include" }).then(res => {
          if (snackbarType?.current === "connectivity") {
            closeSnackbar();
          }
          return res;
        });
      },
      uri,
    };

    const httpLink = new HttpLink({
      ...httpOpts,
    });

    const batchLink = new BatchHttpLink({
      batchInterval: 10,
      batchMax: 10,
      batchKey: op => {
        // Same as https://github.com/apollographql/apollo-client/blob/aa7b6a2/src/link/batch-http/batchHttpLink.ts#L231-L245
        // but with addition of `batch` context key which allows separating batches if needed
        const batch = (op.getContext().batch ?? true) || generateRandomId();

        const context = op.getContext();
        const contextConfig = {
          http: context.http,
          options: context.fetchOptions,
          credentials: context.credentials,
          headers: context.headers,
        };

        return selectURI(op, uri) + JSON.stringify(contextConfig) + batch;
      },
      ...httpOpts,
    });

    cachePersistor.restoreSync();

    return new ApolloClient({
      cache: cachePersistor.cache.cache,
      connectToDevTools:
        env.glueEnv === "development" || env.glueEnv === "staging",
      credentials: "include",
      defaultOptions: {
        query: {
          partialRefetch: true,
        },
        watchQuery: {
          partialRefetch: true,
          refetchWritePolicy: "merge",
          // disable for now... TS types are not partial so need some work
          // returnPartialData: true,
        },
      },
      link: from([
        authLink,
        errorLink,
        sanitizeLink,
        timeoutLink.split(
          op => op.getContext().batch === false,
          httpLink,
          batchLink
        ),
      ]),
    });
  }, [cachePersistor, openSnackbar, snackbarType, closeSnackbar]);

  const clearApolloCache = useCallback(async () => {
    await apolloClient.clearStore();
    await cachePersistor.purge();
  }, [apolloClient, cachePersistor]);

  return {
    apolloClient,
    clearApolloCache,
  };
};
