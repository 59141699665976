import {
  ComponentProps,
  ContextType,
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { noop } from "lodash-es";

import usePreviousRef from "hooks/usePreviousRef";

import SnackBar from "components/SnackBar/SnackBar";

type SnackbarContent =
  | Pick<ComponentProps<typeof SnackBar>, "content">["content"]
  | undefined;

type SnackbarTypes =
  | Pick<ComponentProps<typeof SnackBar>, "type">["type"]
  | undefined;

type ProviderContextValue = {
  closeSnackbar: () => void;
  openSnackbar: (
    type: SnackbarTypes,
    content?: SnackbarContent,
    dismissTimer?: number
  ) => void;
  openType?: MutableRefObject<SnackbarTypes>;
};

export const SnackbarProvider = ({ children }: WithChildren) => {
  const [show, setShow] = useState<boolean>(false);
  const [type, setType] = useState<SnackbarTypes>();
  const [content, setContent] = useState<SnackbarContent>();

  const openTypeRef = usePreviousRef<SnackbarTypes>(type);
  const dismissTimerRef = useRef<ReturnType<typeof setTimeout>>();

  const close = useCallback(() => {
    setShow(false);
    if (dismissTimerRef.current) {
      clearTimeout(dismissTimerRef.current);
      dismissTimerRef.current = undefined;
    }
  }, []);

  const contextPayload: ProviderContextValue = useMemo(
    () => ({
      closeSnackbar: close,
      openSnackbar: (type, content, dismissTimer) => {
        if (type === "info" || type === "success") {
          dismissTimer ??= 5000;
        }

        close();
        setShow(true);
        setType(type);
        setContent(content);
        if (dismissTimer) {
          dismissTimerRef.current = setTimeout(close, dismissTimer);
        }
      },
      openType: openTypeRef,
    }),
    [openTypeRef, close]
  );

  return (
    <SnackbarContext.Provider value={contextPayload}>
      {children}
      {type && (
        <SnackBar content={content} onClose={close} show={show} type={type} />
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar: () => ContextType<typeof SnackbarContext> = () =>
  useContext(SnackbarContext);

export const SnackbarContext = createContext<ProviderContextValue>({
  closeSnackbar: noop,
  openSnackbar: (
    _type: SnackbarTypes,
    _content?: SnackbarContent,
    _dismissTimer?: number
  ) => noop,
});
