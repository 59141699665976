import { addDays, addHours, nextMonday, set } from "date-fns";

export enum ReminderOption {
  ONE_HOUR = "1_HOUR",
  THREE_HOURS = "3_HOURS",
  TOMORROW = "TOMORROW",
  NEXT_WEEK = "NEXT_WEEK",
  CUSTOM = "CUSTOM",
}

export const reminderOptionLabels: Record<ReminderOption, string> = {
  [ReminderOption.ONE_HOUR]: "In 1 hour",
  [ReminderOption.THREE_HOURS]: "In 3 hours",
  [ReminderOption.TOMORROW]: "Tomorrow at 9:00 AM",
  [ReminderOption.NEXT_WEEK]: "Monday at 9:00 AM",
  [ReminderOption.CUSTOM]: "Custom...",
};

export const reminderOptions: { label: string; value: ReminderOption }[] = [
  {
    label: reminderOptionLabels[ReminderOption.ONE_HOUR],
    value: ReminderOption.ONE_HOUR,
  },
  {
    label: reminderOptionLabels[ReminderOption.THREE_HOURS],
    value: ReminderOption.THREE_HOURS,
  },
  {
    label: reminderOptionLabels[ReminderOption.TOMORROW],
    value: ReminderOption.TOMORROW,
  },
  {
    label: reminderOptionLabels[ReminderOption.NEXT_WEEK],
    value: ReminderOption.NEXT_WEEK,
  },
  {
    label: reminderOptionLabels[ReminderOption.CUSTOM],
    value: ReminderOption.CUSTOM,
  },
];

const setTimeTo9am = (date: Date) =>
  set(date, {
    hours: 9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

/**
 * Retrieve reminder date based on relative reminder options.
 *
 * All values are relative to the current date and time.
 */
export const getReminderDate = (
  reminder: Exclude<ReminderOption, ReminderOption.CUSTOM>
) => {
  switch (reminder) {
    case ReminderOption.ONE_HOUR:
      return addHours(new Date(), 1);
    case ReminderOption.THREE_HOURS:
      return addHours(new Date(), 3);
    case ReminderOption.TOMORROW:
      return setTimeTo9am(addDays(new Date(), 1));
    case ReminderOption.NEXT_WEEK:
      return setTimeTo9am(nextMonday(new Date()));
  }
};
