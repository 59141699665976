type Props = {
  title: string;
  headline: string;
};

const ContentWrapper = ({ children, title, headline }: WithChildren<Props>) => {
  return (
    <div
      className="flex flex-col w-full h-full md:max-w-400 pt-0 md:pt-[68px] mx-auto text-left"
      data-testid="onboarding-content-wrapper"
    >
      <div className="flex flex-col mb-24 md:mb-32 w-full">
        <span className="text-title-3">{title}</span>
        <span className="text-headline text-text-secondary">{headline}</span>
      </div>
      <div className="flex flex-col w-full h-full overflow-hidden mb-[env(safe-area-inset-bottom)]">
        {children}
      </div>
    </div>
  );
};

export default ContentWrapper;
