import { ThreadsMailbox } from "generated/graphql";
import { useEffect, useMemo, useState } from "react";
import useInstantSearch from "./useInstantSearch";

const useThreadListSearch = (mailbox: ThreadsMailbox) => {
  const [match, setMatch] = useState("");
  const { search, searchResults } = useInstantSearch({
    resultsOrder: ["threads"],
  });

  useEffect(() => {
    search({
      groups: false,
      mailbox: mailbox,
      match,
      users: false,
    });
  }, [mailbox, match, search]);

  const foundThreads = useMemo(
    () =>
      searchResults.instantResults.flatMap(r =>
        r.resultType === "threads" ? r.edges : []
      ),
    [searchResults]
  );

  const isFindingThreads = match.length > 0;

  return {
    foundThreads,
    isFindingThreads,
    searching: searchResults.searching,
    setMatch,
  };
};

export default useThreadListSearch;
