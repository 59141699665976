import { matchPath } from "react-router-dom";

import * as Sentry from "@sentry/react";

import env from "utils/processEnv";

import { PathMatch } from "components/routing/utils";
import { isNative } from "utils/platform";
import { history } from "./InstrumentedRouter";
import { nativeVersionInfo, versionInfo } from "./version";

(async () => {
  const { fullVersionString } = versionInfo;
  Sentry.setTag("client_version", fullVersionString);

  if (isNative()) {
    const { version, buildNumber } = await nativeVersionInfo;
    Sentry.setTags({
      client_native_build_number: buildNumber,
      client_native_version: version,
    });
  }

  Sentry.getCurrentScope().addEventProcessor(event => ({
    ...event,
    release: fullVersionString,
  }));
})();

const {
  glueApiUrl,
  glueEnv: environment,
  sentryDsn: dsn,
  sentryTracesSampleRate,
  sentryReplaysSampleRate,
} = env;

if (dsn) {
  const routes = Object.values(PathMatch);
  Sentry.init({
    dsn,
    environment,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath,
      }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: true,
        maskAllInputs: true,
        minReplayDuration: 5 * 1000, // 5 seconds
        maxReplayDuration: 5 * 60 * 1000, // 5 minutes
      }),
    ],
    maxBreadcrumbs: 5,
    replaysSessionSampleRate: sentryReplaysSampleRate,
    replaysOnErrorSampleRate: 0, // buffered recording is too expensive
    tracePropagationTargets: [new URL(glueApiUrl).origin],
    tracesSampleRate: sentryTracesSampleRate,
  });
}
