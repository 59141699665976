import { Skeleton } from "components/Skeleton";
import Avatar from "components/design-system/Avatar/Avatar";
import getRandomInt from "utils/getRandomInt";

type GroupAvatarAndTitleProps = {
  avatarURL?: string | null;
  children: string;
  emoji?: string;
  isLoading?: boolean;
  onClick?: () => void;
};

export const GroupAvatarAndTitle = ({
  avatarURL,
  children,
  emoji,
  isLoading,
  onClick,
}: GroupAvatarAndTitleProps) => {
  if (isLoading) {
    return (
      <div className="flex items-center gap-8">
        <Skeleton className="w-24 h-24" />
        <Skeleton width={`${getRandomInt(200, 250)}px`} />
      </div>
    );
  }

  const Content = () => {
    return (
      <>
        <Avatar
          avatarURL={avatarURL}
          background="transparent"
          emojiProps={{
            emoji,
          }}
          name={children}
          rounded="rounded-sm"
          size="small"
        />
        <div className="text-title-3 truncate">{children}</div>
      </>
    );
  };

  if (!onClick) {
    return (
      <div className="flex items-center gap-8 cursor-default select-none">
        <Content />
      </div>
    );
  }

  return (
    <button
      className="flex items-center gap-8 overflow-hidden"
      data-testid="recipient-heading"
      onClick={onClick}
    >
      <Content />
    </button>
  );
};
