import { useApolloClient } from "@apollo/client";
import { Skeleton } from "components/Skeleton";
import { cloneElementForSkeletons } from "components/Skeleton/Skeleton";
import ActivityEmptyView from "components/views/activity/ActivityMain/ActivityEmptyView";
import {
  FeedType,
  NotificationsDocument,
  NotificationsQuery,
  useNotificationsQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { glueCreateZustand } from "store/helper/glueCreate";
import useAppStateStore from "store/useAppStateStore";
import getRandomInt from "utils/getRandomInt";
import { persist } from "zustand/middleware";
import ActivityItem from "./ActivityItem";

type Props = {
  feedType: FeedType;
  scrollRef?: (el: HTMLUListElement | null) => void;
};

const NAME = "activity-list-select";
const useSelectStateStore = glueCreateZustand<{
  selected?: string;
}>({
  name: NAME,
})(
  persist(() => ({}), {
    name: NAME,
  })
);

// workaround for IDs not matching see https://github.com/gluegroups/glue-api/issues/899
const getNotificationID = (id: string) => `id${id.slice(12)}`;

export default function ActivityList({ feedType, scrollRef }: Props) {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const { authReady } = useAuthData();
  const selected = useSelectStateStore().selected;

  const { location } = useHistory();
  const client = useApolloClient();
  const [newEdgeIDs, setNewEdgeIDs] = useState<string[]>([]);
  const [unseenEdgeIDs, setUnseenEdgeIDs] = useState<string[]>([]);

  const fetchPolicy = authReady ? "cache-and-network" : "cache-only";
  const onCompleted = ({ notifications }: NotificationsQuery) => {
    const newEdgeIDs = notifications.edges
      .filter(e => !e.isSeen)
      .map(e => e.id);
    if (newEdgeIDs.length) {
      setNewEdgeIDs(newEdgeIDs);
    }
  };

  const { data, refetch, loading } = useNotificationsQuery({
    fetchPolicy,
    variables: { filter: { feedType }, markEdgesSeen: newEdgeIDs },
    onCompleted,
  });

  const notificationEdges = (data?.notifications.edges || []).filter(
    ({ node: { activities } }) => !!activities[0]?.object?.__typename
  );
  const activityCount = notificationEdges.length;

  const skeletonItems = useMemo(
    () =>
      cloneElementForSkeletons(
        <li className="py-16 px-20 border-b border-border-strong hover:bg-background-list-hover overflow-hidden">
          <div className="overflow-x-hidden">
            <div className="w-full min-w-0 pointer-events-none">
              <div className="flex items-center mb-8 min-w-0 overflow-hidden w-full">
                <div className="mr-4 text-icon-primary" />
                <div className="truncate text-text-primary text-footnote">
                  <Skeleton width={`${getRandomInt(250, 400)}px`} />
                </div>
                <Skeleton width={`${getRandomInt(250, 400)}px`} />
              </div>
              <div className="flex flex-row">
                <div className="mr-12">
                  <Skeleton
                    className="rounded-md w-40 h-40"
                    flexGrow={false}
                    flex
                  />
                </div>
                <div>
                  <Skeleton
                    height="14px"
                    width={`${getRandomInt(100, 250)}px`}
                  />
                </div>
              </div>
            </div>
          </div>
        </li>,
        5
      ),
    []
  );

  useEffect(() => {
    refetch();
  }, [refetch, newEdgeIDs]);

  useEffect(() => {
    setUnseenEdgeIDs(unseenEdgeIDs => [
      ...unseenEdgeIDs,
      ...newEdgeIDs.map(getNotificationID),
    ]);
  }, [newEdgeIDs]);

  useEffect(() => {
    if (newEdgeIDs.length) return;
    const cachedData = client.readQuery<NotificationsQuery>({
      query: NotificationsDocument,
    });
    if (cachedData) {
      const newEdgeIDs = cachedData.notifications.edges
        .filter(e => !e.isSeen)
        .map(e => e.id);
      setNewEdgeIDs(newEdgeIDs);
    }
  }, [client, newEdgeIDs.length]);

  useEffect(() => {
    if (!breakpointMD && location.pathname === "/activity") {
      useSelectStateStore.setState({ selected: undefined });
    }
  }, [location.pathname, breakpointMD]);

  if (activityCount === 0 && !loading)
    return <ActivityEmptyView type={feedType} />;

  return (
    <ul className="overflow-y-auto" ref={scrollRef}>
      {(loading && !data?.notifications.edges) || !authReady
        ? skeletonItems
        : notificationEdges.map(n => {
            const id = getNotificationID(n.id);
            return (
              <ActivityItem
                key={id}
                isRead={!unseenEdgeIDs.includes(id)}
                notification={n.node}
                onClick={e => {
                  !e?.ctrlKey &&
                    !e?.metaKey &&
                    useSelectStateStore.setState({ selected: id });
                  setUnseenEdgeIDs(unseenEdgeIDs.filter(i => i !== id));
                }}
                selected={selected === id}
              />
            );
          })}
    </ul>
  );
}
